// German (Language-Country Code: de-DE) Locale File.
import defaultsDeep from 'lodash.defaultsdeep';

import en_US, { PublicLocalizationObject } from './en_US';

const de_DE: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            backToActivityTimes: 'Zurück zu Aktivitätszeiten',
            backToActivityDetails: 'Zurück zu Aktivitätsdetails',
            backToEvent: 'Zurück zum Event',
            location: 'Ort',
            rsvp: '',
            entry: '',
            soldOut: 'AUSGEBUCHT',
            cancel: 'Abbrechen',
            close: 'Schließen',
            confirm: 'Bestätigen',
            exit: '',
            signInCreateAccount: 'Anmelden/Konto erstellen',
            signIn: 'Anmelden',
            warpPipePass: '',
            learnMore: 'Weitere Infos',
            currentLanguage: 'Deutsch',
            yes: '',
            no: '',
            note: 'HINWEIS',
            addressFormat: '{address} {postal_code}, {city} {region}',
            addressFormatHideRegion: '{address} {postal_code}, {city}',
            areYouSure: 'Bist du sicher?',
        },
        tabs: {
            events: 'Events',
            myRSVPs: '',
            myTicketsAndPasses: 'Meine Tickets/Passes',
            checkIn: '',
            signIn: '',
        },
        events: {
            eventItemHeader: 'Event:',
            upcoming: '',
            moreInfo: 'Weitere Infos',
            eventDetails: 'Eventdetails',
            viewEventDetails: 'Eventdetails ansehen',
            details: {
                tabs: {
                    tickets: 'Tickets',
                    activities: 'Aktivitäten',
                    resources: '',
                },
                accessCode: {
                    aria: {
                        accessCodeInput: 'Zugangscode',
                        exitButton: 'Zugangscode-Eingabe verlassen',
                    },
                    title: 'Zugangscode eingeben',
                    toggleButtonLabel: 'Zugangscode verwenden',
                    redeemButtonLabel: 'Verwenden',
                    inputPlaceHolder: '16-stelliger Zugangscode',
                    enterDifferentCode: 'Anderen Zugangscode eingeben',
                    success: {
                        woohoo: 'Glückwunsch!',
                        youCanRegister: 'Du kannst dich jetzt anmelden für:',
                    },
                    error: {
                        invalid: 'Ungültiger Code. Versuche es bitte erneut.',
                        registrationClosed:
                            'Anmeldungen für dieses Ticket sind nicht mehr möglich.',
                    },
                },
                waitlist: {
                    available: 'Warteliste verfügbar:',
                    drawingOver:
                        'Die Lotterie ist beendet, aber du hast vielleicht noch die Chance teilzunehmen! Trage dich in die Warteliste ein und wir werden dich informieren, sobald etwas verfügbar wird. Beachte bitte, dass ein Platz in der Warteliste keine Garantie für den Erhalt von Tickets darstellt.',
                    drawingLost:
                        'Dein Lotterielos zur Teilnahme an diesem Event wurde nicht gezogen. Wenn du möchtest, kannst du dich in die Warteliste eintragen und wir informieren dich, sobald etwas verfügbar wird. Beachte bitte, dass ein Platz in der Warteliste keine Garantie für den Erhalt von Tickets darstellt.',
                    waitlistJoined: 'Du bist auf der Warteliste für {eventName}',
                    waitlistLink: 'Deine Warteliste ansehen >',
                    waitlistOffer:
                        'Hurra! Für einen deiner Wartelistenplätze ist ein Ticketangebot verfügbar. Du kannst es bis zum {monthDay} um {time} Uhr in Anspruch nehmen. Danach wird es der nächsten Person auf der Warteliste angeboten.',
                    offerPassed:
                        'Du hast das Angebot für {ticketName} nicht in Anspruch genommen. Wenn du möchtest, kannst du dich erneut in die Warteliste eintragen.',
                    offerExpired:
                        'Dein Anspruch auf {ticketName} ist verfallen. Wenn du möchtest, kannst du dich erneut in die Warteliste eintragen.',
                    waitlistClosed: 'Die Warteliste ist geschlossen.',
                    button: {
                        join: 'In Warteliste eintragen',
                        edit: 'Deine Warteliste bearbeiten',
                    },
                },
            },
            dataAttribute: {
                FULL_NAME: 'Voller Name',
                FIRST_NAME: 'Vorname',
                LAST_NAME: 'Nachname',
                POSTAL_CODE: 'Postleitzahl',
                PHONE: 'Telefon/Handy',
                US_ADDRESS: 'Adresse',
                MAILING_ADDRESS: {
                    streetAddress: 'Straße',
                    aptOrSuite: 'Appartement/Zimmer',
                    city: 'Stadt',
                    state: 'Bundesland',
                    country: 'Land',
                    zipCode: 'Postleitzahl',
                },
                OPT_IN: 'Dem Teilen von E-Mails zustimmen',
                ACCESS_CODE: 'Zugangscode',
            },
            ageRatingUrl: 'https://usk.de/',
        },
        activities: {
            activities: 'Aktivitäten',
            viewActivities: 'Aktivitäten ansehen',
            registrationNotBegun: 'Die Anmeldung für diese Aktivität hat noch nicht begonnen.',
            registrationHasEnded: 'Die Anmeldung für diese Aktivität ist beendet.',
            registrationBegins: 'Beginn der Anmeldung:',
            details: {
                title: 'Aktivitätsdetails',
                registrationBegins: 'Anmeldungsstart:',
                registrationEnds: 'Anmeldungsende:',
                header: {
                    noAvailableTimes: 'Leider stehen keine Termine zur Verfügung.',
                },
                continueToRSVP: 'Weiter',
                viewMy: 'Meinen {0} ansehen',
                alreadyRSVPd: 'Du besitzt bereits einen Warp Pipe Pass für diese Aktivität.',
                ticketIsRequired:
                    'Zur Anmeldung für diese Aktivität ist ein Ticket dieses Events erforderlich. {link}.',
                eventTicketRequired: 'Event-Ticket erforderlich',
                ticketIsPendingDrawing:
                    'Die Lotterie für dein Event-Ticket hat noch nicht begonnen. Versuche es nach der Lotterie erneut.',
                checkForTicketAvailability: 'Überprüfe hier die Verfügbarkeit von Tickets.',
                label: {
                    startDate: 'Startdatum: {startDate}',
                    activityType: 'Aktivitätstyp: {activityType}',
                },
                iconLabel: {
                    activityTime: 'Symbol für die Aktivitätszeit',
                    activityType: 'Symbol für den Aktivitätstyp',
                    registration: 'Symbol für den Anmeldungstyp',
                    location: 'Symbol für den Ort',
                },
            },
            timeSlots: {
                title: '',
                noTimesForDay: 'Für diesen Tag stehen keine Termine zur Verfügung.',
            },
            unknownActivityType: '',
            types: {
                GAME_DEMO: 'Spieledemo',
                GENERAL_CHECK_IN: 'Allgemeiner Check-in',
                COMPETITIVE_PLAY: 'Wettkampf-Spiel',
                EXCLUSIVE_OFFER: 'Exklusives Angebot',
                LEAD_GENERATION: 'Leadgenerierung',
                MEET_GREET: 'Meet & Greet',
                PRODUCT_LAUNCH: 'Produktveröffentlichung',
                SPECIAL_EVENT: 'Spezialevent',
                unknown: '',
            },
        },
        reservations: {
            timeRemainingForReservation:
                'Verbleibende Zeit: {timeRemaining}. Falls du diese Seite verlässt oder die Zeit abläuft, bevor die Anmeldung abgeschlossen ist, wird dieser Vorgang abgebrochen.',
            timeRemainingForDrawing:
                'Verbleibende Zeit, diesen Antrag auf Lotterieteilnahme zu vervollständigen: {timeRemaining}',
            timeRemainingForWaitlist:
                'Fantastisch! Du hast ein Wartelistenangebot für {numTickets} x {ticketName}. Wenn du Interesse daran hast, dann nimm die Tickets bitte in Anspruch, bevor die Zeit abläuft: {timeRemaining}',
            waitlistThingsToKeepInMind: 'Was du wissen solltest:',
            waitlistThingsToKeepInMindItem1:
                'Wenn du diese Tickets in Anspruch nimmst, wirst du für alle anderen {eventName}-Tickets aus der Warteliste ausgetragen.',
            waitlistThingsToKeepInMindItem2:
                'Das Angebot geht nicht verloren, wenn du diese Anmeldeseite verlässt. Dir bleibt Zeit bis zum angegebenen Datum, das/die Ticket/s in Anspruch zu nehmen.',
            waitlistThingsToKeepInMindItem3:
                'Du kannst das Ticketangebot ablehnen, falls du kein Interesse mehr daran hast. Dann wird es der nächsten Person auf der Warteliste angeboten.',
            waitlistThingsToKeepInMindItem4:
                'Das Ablehnen der Tickets hat keinen Einfluss auf andere Wartelistenplätze für {eventName}. Du kannst dich auch später erneut in die Warteliste eintragen.',
            minutes: ' Minute/n',
            registrant: '',
            registrantInformation: 'Deine Anmeldeinformationen',
            nickname: 'Nintendo-Account-Spitzname',
            confirmRSVP: 'Bestätigen',
            confirmEntry: 'An Lotterie teilnehmen',
            legalConsent: 'Rechtliche Zustimmung',
            adultAcknowledgement:
                'Durch Ankreuzen dieses Kästchens bestätige ich, dass ich und alle Gäste ab 18 Jahren in meiner Familiengruppe die Datenschutzbestimmungen gelesen haben und die unten aufgeführten Teilnahmebedingungen akzeptieren.',
            childAcknowledgement:
                'Durch Ankreuzen dieses Kästchens bestätige ich, dass ich Elternteil/Erziehungsberechtigter von allen Gästen unter 18 Jahren in meiner Familiengruppe bin. Ich erkenne die Datenschutzrichtlinie an und akzeptiere die oben verlinkten Teilnahmebedingungen in ihrem Namen.',
            adultGuestsAcknowledgement:
                'Durch Ankreuzen dieses Kästchens bestätige ich, das Einverständnis meiner volljährigen Gäste zu haben, dass sie zu diesem Event als Gäste angemeldet werden und eine Einladung per E-Mail erhalten. Volljährige Gäste sind dazu verpflichtet, die Teilnahmebedingungen im Anhang der E-Mail vor dem Besuch des Events zu akzeptieren.',
            event_registration: {
                attendees: 'Teilnehmer',
                note: 'HINWEIS:',
                attendeeCannotRegister:
                    'Die Teilnehmer können sich nicht für mehr als eine Aktivität im selben Zeitraum oder für dieselbe Aktivität mehrmals am selben Tag anmelden.',
                currentUserIneligible:
                    'Du bist bereits für einen Warp Pipe Pass für diese oder eine andere Aktivität zur gleichen Zeit angemeldet.',
                removedGuests:
                    'Gast {guests} wurde entfernt, weil er/sie bereits für einen Warp Pipe Pass für die Aktivität angemeldet ist bzw. für eine andere Aktivität zur selben Zeit.',
                selectAllAttendees: 'Alle Teilnehmer dieser Aktivität auswählen',
            },
        },
        tickets: {
            ticket: 'Ticket',
            tickets: 'Tickets',
            wppRequiredForCheckIn: 'Für den Check-in ist dieser {0} erforderlich.',
            wppsRequiredForCheckIn: 'Für den Check-in sind diese {0} erforderlich.',
            ticketRequiredForCheckIn:
                'Für die Teilname an diesem Event ist dieses {0} erforderlich.',
            ticketsRequiredForCheckIn:
                'Für die Teilname an diesem Event sind diese {0} erforderlich.',
            getTickets: 'Tickets beantragen',
            eventTicket: 'Event-Ticket',
            eventTickets: 'Event-Tickets',
            viewTickets: 'Tickets ansehen',
            viewEntry: 'Lotterielos ansehen',
            registrationClosed: 'Anmeldung beendet',
            comingSoon: 'Bald',
            cancelOtherTicket:
                'Storniere bitte dein anderes Ticket, bevor du dich für dieses Ticket anmeldest.',
            soldOut: 'Ausgebucht',
            adultRequired: 'Nur Volljährige zugelassen',
            adultRequiredTooltip: 'Zu diesem Event können sich nur Volljährige anmelden.',
            adultRequiredActivityTooltip:
                'Zu dieser Aktivität können sich nur Volljährige anmelden.',
            backToMyTicketsPasses: 'Zurück zu meinen Tickets/Passes',
            registrationAlreadyClosed: 'Anmeldung beendet am {0} um {1} Uhr',
            registrationNotStarted: 'Anmeldung beginnt am {0} um {1} Uhr',
            notFound: 'Keine Tickets gefunden',
            unknownTicketType: 'Tickettyp unbekannt',
            consent: {
                consentRequiredTooltipTitle: 'Zustimmung erforderlich',
                consentRequiredTooltipBody:
                    'Diese/r Nutzer/in muss durch die Anmeldung mit seinem/ihrem Nintendo-Account die Einladung annehmen und die Teilnahmebedingungen akzeptieren.',
                consentRequiredToolTipBodyHostUrl: 'die Einladung zum Event annehmen',
                consentRequiredToolTipBodyHost:
                    'Du musst {0} und die Teilnahmebedingungen akzeptieren, um an dieser Aktivität teilnehmen zu können.',
            },
            receivedTickets: 'Du hast deine Tickets für {resourceName} {resourceDates} erhalten.',
            drawings: {
                wonDrawing:
                    'Bei der Lotterie wurde dein Los für {resourceName} {resourceDates} gezogen.',
                lostDrawing: 'Leider wurde dein Lotterielos für dieses Ticket nicht gezogen.',
                pendingDrawing:
                    'Du nimmst an der Lotterie für {resourceName} {resourceDates} teil.',
            },
            registration: {
                ticketDetails: 'Ticketdetails',
                registrant: {
                    onlyOneActivity:
                        '{note} Die Teilnehmer können sich nicht für mehr als eine Aktivität im selben Zeitraum oder für dieselbe Aktivität mehrmals am selben Tag anmelden.',
                    dataNotice:
                        'Um mehr darüber zu erfahren, wie wir deine Daten verwenden, lies bitte unsere {link}.',
                    requiredFields: 'Alle mit * markierten Felder müssen ausgefüllt werden.',
                },
                guest: {
                    guest: 'Gast',
                    guests: 'Deine Gäste (max. {maxGuests})',
                    birthday: 'Geburtsdatum',
                    addManageGuests: 'Gäste hinzufügen/bearbeiten',
                    manageNintendoAccountFamily: 'Nintendo-Account-Familiengruppe bearbeiten',
                    guestsRemovedTicket:
                        'Gast {guests} wurde entfernt, weil er/sie bereits für ein Ticket für diese Event angemeldet ist.',
                    guestsRemovedDrawing:
                        'Gast {guests} wurde entfernt, weil er/sie bereits an der Lotterie für ein Ticket für dieses Event teilnimmt.',
                    maxLimit: 'Es sind maximal {max} Gäste zugelassen.',
                    guestFamilyHelp:
                        'Hast du noch keine Familiengruppe erstellt? {guestFamilyHelpLink}',
                    guestFamilyHelpLink:
                        'Erfahre hier, wie du eine Gruppe erstellst und bearbeitest.',
                    guestFamilyHelpUrl:
                        'https://www.nintendo.com/de-de/Support/Nintendo-Switch/Das-Hinzufugen-von-Mitgliedern-zu-einer-Familiengruppe-1406409.html',
                    guestFamilyWarning:
                        'Gäste können nur von deiner {nintendoFamilyLink} hinzugefügt werden. Wenn deine Gäste nicht in der Liste erscheinen, füge sie bitte zuerst zu deiner Nintendo-Account-Familiengruppe hinzu.',
                    guestFamilyLink: 'Nintendo-Account-Familiengruppe',
                    guestFamilyUrl:
                        'https://www.nintendo.com/de-de/Support/Nintendo-Switch/Das-Hinzufugen-von-Mitgliedern-zu-einer-Familiengruppe-1406409.html',
                    guestRemoveButtonLabel: 'Nutzer {user} aus der Gruppe löschen',
                    selectFamilyMember: 'Aus verfügbarer Nintendo-Account-Familiengruppe wählen',
                    counter: '{current}/{max} Gast/Gäste ausgewählt',
                    child: 'Kind',
                    notSelectableIf: 'Familienmitglieder können nicht ausgewählt werden, wenn',
                    reachedMaxGuests: '1. die maximale Anzahl an Gästen bereits erreicht wurde.',
                    childAndNotParent:
                        '2. sie Kinder sind und du nicht deren Elternteil/Erziehungsberechtigter.',
                    alreadyRegistered: '3. sie bereits angemeldet sind.',
                },
            },
            cancelTicket: {
                cancelTicketModalTitle: 'Ticket stornieren',
                cancelLink: 'Dieses Ticket stornieren',
                cancelTicketModalWarning: {
                    line1: 'Du kannst dieses Event nicht ohne Ticket besuchen.',
                    line2: 'Dein Ticket und dein/e Warp Pipe Pass/es werden storniert.',
                    line3: 'Durch das Stornieren deines Tickets werden automatisch Tickets und Warp Pipe Passes all deiner Gruppenmitglieder (gegebenenfalls) storniert. Jede/r Betroffene wird eine Stornierungsbestätigung per E-Mail erhalten.',
                    line4: 'Alle Stornierungen sind endgültig. Im Falle einer Stornierung werden Tickets und Warp Pipe Passes nicht zurückerstattet.',
                },
                areYouSure: 'Bist du sicher, dass du dieses Ticket stornieren möchtest?',
                imSure: 'Ja, ich bin mir sicher.',
                cancelButton: 'Ticket stornieren',
                cancelYourTicket: 'Schaffst du es zeitlich nicht, dann storniere dein/e Ticket/s.',
            },
            cancelPass: {
                cancelTicketModalTitle: 'Pass stornieren',
                cancelLink: 'Diesen Pass stornieren',
                areYouSure: 'Bist du sicher, dass du diesen Pass stornieren möchtest?',
                imSure: 'Ja, ich bin mir sicher.',
                cancelButton: 'Pass stornieren',
            },
            cancelDrawingEntry: {
                cancelTicketModalTitle: 'Lotterielos zurückziehen',
                cancelLink: '',
                cancelTicketModalWarning: {
                    line1: 'Du wirst von der Lotterie für dieses Ticket ausgeschlossen.',
                    line2: 'Durch das Zurückziehen deines Lotterieloses wird automatisch die Lotterieteilnahme für all deine Gruppenmitglieder (gegebenenfalls) widerrufen. Jede/r Betroffene wird eine Bestätigung der Widerrufung per E-Mail erhalten.',
                    line3: '',
                    line4: '',
                },
                areYouSure: 'Bist du sicher, dass du dieses Lotterielos zurückziehen möchtest?',
                imSure: 'Ja, ich bin mir sicher.',
                cancelButton: 'Lotterielos zurückziehen',
            },
            cancelPassDrawingEntry: {
                cancelTicketModalTitle: 'Lotterielos zurückziehen',
                cancelLink: '',
                cancelTicketModalWarning: {
                    line1: 'Du wirst von der Lotterie für dieses Ticket ausgeschlossen.',
                    line2: 'Durch das Zurückziehen deines Lotterieloses wird automatisch die Lotterieteilnahme für all deine Gruppenmitglieder (gegebenenfalls) widerrufen. Jede/r Betroffene wird eine Bestätigung der Widerrufung per E-Mail erhalten.',
                    line3: '',
                    line4: '',
                },
                areYouSure: 'Bist du sicher, dass du dieses Lotterielos zurückziehen möchtest?',
                imSure: 'Ja, ich bin mir sicher.',
                cancelButton: 'Lotterielos zurückziehen',
            },
            waitlist: {
                waitlist: 'Warteliste',
                title: 'In die Warteliste eintragen für {eventName}',
                editTitle: 'Warteliste bearbeiten für {eventName}',
                subtitle: 'Wir informieren dich, sobald ein Platz frei wird!',
                notice: {
                    line1: '{notice}: Die Ticketangebote richten sich danach, wie zügig man sich in die Warteliste einträgt, wie viele angefragte Tickets es gibt und welche Tickets verfügbar werden.',
                    line2: 'Du kannst immer nur jeweils ein Angebot erhalten.',
                    line3: 'Du kannst dich innerhalb von {timeFrame} für das Event anmelden, danach verfällt das Angebot.',
                    line4: 'Wenn du ein Angebot annimmst, wirst du für eventuell verbleibende Tickets aus der Warteliste ausgetragen.',
                    notice: 'Wichtig',
                    timeFrame: '36 Stunden',
                },
                individualTicketsHeader: 'Individuelle Tickets',
                familyTicketsHeader: 'Familientickets',
                guests: 'Gäste',
                individual: 'Individuell',
                family: 'Familie',
                adultGuests: 'Volljährige Gäste (18+)',
                childGuests: 'Gäste unter 18 (7-17)',
                guestCount: '{currentGuests}/{maxGuests} Gast/Gäste ausgewählt',
                select: 'Wähle die Warteliste/n in die du dich eintragen möchtest',
                selectAll: 'Alle Tickets Typ {waitlistType} wählen',
                submit: 'In Warteliste eintragen',
                summary: 'Überblick',
                summaryRequired:
                    'Du musst die Teilnahmebedingungen akzeptieren, um dich in die Warteliste eintragen zu können.',
                summaryCheckbox:
                    'Durch Ankreuzen dieses Kästchens bestätige ich, verstanden zu haben, dass ein Platz auf der Warteliste keine Garantie dafür ist, dass ich Tickets erhalte. Falls sich die Gelegenheit ergibt, kann ich innerhalb von 36 Stunden das Angebot annehmen. Danach verfällt es.',
                summaryFooter:
                    'Du bist im Begriff, einen Antrag auf einen Wartelistenplatz für {eventName} zu stellen. Du wirst umgehend per E-Mail benachrichtigt, sobald Tickets zur Verfügung stehen. Um sicherzustellen, dass du weiterhin E-Mails von uns erhältst, füge bitte die von uns als Absender verwendete E-Mail-Adresse ({emailAddress}) deinem Adressbuch hinzu. Sieh eventuell bitte auch in deinem Spam-Ordner nach. Ticketangebote sind 36 Stunden lang gültig und müssen innerhalb dieses Zeitraums in Anspruch genommen werden, bevor sie verfallen.',
                summaryEmail: 'no-reply@noa.nintendo.com',
                onTheWaitlist: 'Du bist auf der Warteliste für',
                leaveWaitlists: 'Wartelisten verlassen',
                leaveWaitlist: 'Warteliste verlassen',
                makeChanges: 'Bearbeiten',
                cancelEntry: 'Antrag abbrechen',
                onWaitlistRequestingTickets: 'In der Warteliste ({count} Event-Ticket/s angefragt)',
                confirmRemoveSelfFromWaitlistsForEvent:
                    'Du bist im Begriff, dich aus der Warteliste für {eventName} auszutragen und wirst daraufhin nicht benachrichtigt, falls Tickets verfügbar werden.',
                confirmRemoveSelfFromWaitlist:
                    'Das Abbrechen deines Antrags führt dazu, dass du aus der Warteliste für {ticketName} ausgetragen wirst und nicht benachrichtigt wirst, falls Tickets verfügbar werden.',
                stayOnWaitlists: 'Auf den Wartelisten bleiben',
                stayOnWaitlist: 'Auf der Warteliste bleiben',
                noteOtherWaitlistsUnaffected:
                    'Beachte bitte, dass dies keinen Einfluss hat auf andere Wartelisten, in die du dich vielleicht eingetragen hast.',
                congratulationsTicketWaitlistOffered:
                    'Glückwunsch! Auf dich wartet ein Ticketangebot für {eventName}! Nimm es bis zum {claimByDate} in Anspruch, um dir einen Platz zu sichern.',
                congratulationsEventTicketWaitlistOffered:
                    'Glückwunsch! Auf dich wartet ein Ticketangebot für {eventName}! Erweitere die Ansicht, um mehr Informationen anzuzeigen.',
                claimTickets: 'Tickets annehmen',
                passOnTickets: 'Tickets ablehnen',
                areYouSureYouDontWantIt: 'Möchtest du das Angebot wirklich ablehnen?',
                passOnTicketsNotice:
                    'Wenn du das Ticketangebot ablehnst, wirst du die angebotenen Tickets nicht erhalten und aus der Warteliste ausgetragen.',
                keepOffer: 'Angebot annehmen',
                passOnOffer: 'Angebot ablehnen',
                removeWaitlistTicketsWarning:
                    'Das Abbrechen deines Antrags führt dazu, dass du aus der Warteliste für jegliche nicht ausgewählte Tickets ausgetragen wirst und nicht benachrichtigt wirst, falls Tickets verfügbar werden.',
                updateWaitlist: 'Warteliste aktualisieren',
                theWaitlistIsClosed: 'Die Warteliste ist geschlossen.',
                youClaimed: 'Du hast {ticketsCount} für {eventName} beansprucht.',
                yourOfferHasExpired:
                    'Das Angebot für {ticketName} ist verfallen. Wenn du möchtest, kannst du dich erneut in die Warteliste eintragen.',
                youPassedOnThisOffer:
                    'Du hast das Angebot für {ticketName} abgelehnt. Wenn du möchtest, kannst du dich erneut in die Warteliste eintragen.',
                offerExpired: 'Angebot verfallen',
                offerPassed: 'Angebot abgelehnt',
                noLongerOnTheWaitlist:
                    'Du bist nicht mehr auf der Warteliste für {eventName}. Wenn du möchtest, kannst du dich erneut in die Warteliste eintragen.',
            },
            filter: {
                allDays: 'Alle Tage',
                filterTicketsLabel: 'Tickets filtern:',
                ticketTypes: {
                    ALL: 'Alle Tickets',
                    INDIVIDUAL: 'Individuelle Tickets',
                    FAMILY: 'Familientickets',
                },
            },
            appleWalletAddButtonAlt: 'Zu Apple Wallet hinzufügen',
        },
        terms: {
            acceptTerms: 'Teilnahmebedingungen akzeptieren',
            acceptEventTerms: 'Eventteilnahmebedingungen akzeptieren',
            addedToDrawing:
                'Du nimmst an der Lotterie für ein Ticket für das folgende Nintendo-Event von Gastgeber/in {invitor} teil. Um an der Lotterie teilnehmen zu können, musst du die Teilnahmebedingungen akzeptieren.',
            addedToEvent:
                'Du hast ein Ticket erhalten für das folgende Nintendo-Event von Gastgeber/in {invitor}. Um das Event besuchen zu können, musst du die Teilnahmebedingungen akzeptieren.',
            completeInformation: 'Vervollständige bitte alle erforderlichen Angaben:',
            terms: '',
            viewAndAcceptTerms: 'Teilnahmebedingungen lesen und akzeptieren',
            pendingTermsConsent: 'Rechtliche Zustimmung fehlt',
        },
        error: {
            error: 'Fehler',
            warning: 'Wichtig',
            modal: {
                close: 'Schließen',
            },
            notFound: {
                title: 'Leider konnte die gesuchte Seite oder das gesuchte Event nicht gefunden werden.',
                viewAvailableEvents: 'Verfügbare Events ansehen',
            },
            eventsError:
                'Während der Suche nach Events ist ein Fehler aufgetreten. Versuche es bitte später erneut.',
            eventDetailsError:
                'Während der Suche nach Eventdetails ist ein Fehler aufgetreten. Versuche es bitte später erneut.',
            activityDetailsError:
                'Während der Suche nach Aktivitätsdetails ist ein Fehler aufgetreten. Versuche es bitte später erneut.',
            timeSlotError:
                'Während der Suche nach Terminen ist ein Fehler aufgetreten. Versuche es bitte später erneut.',
            tempReservationError:
                'Während der Eingabe dieser Anmeldung ist ein Fehler aufgetreten. Versuche es bitte später erneut.',
            myRSVPsError:
                'Während der Suche nach Einladungsbestätigungen ist ein Fehler aufgetreten. Versuche es bitte später erneut.',
            mapError: 'Die Karte des Event-Orts kann im Moment nicht angezeigt werden.',
            dataCollectionError:
                'Während der Suche nach Daten ist ein Fehler aufgetreten. Versuche es bitte später erneut.',
            reservationCouldNotBeConfirmed:
                'Die Anmeldung konnte nicht bestätigt werden. Versuche es bitte später erneut.',
            reservationCancelFailure:
                'Während der Stornierung ist ein Fehler aufgetreten. Versuche es bitte später erneut.',
            reservationFormError:
                'Es müssen alle erforderlichen Angaben entsprechend markiert sein.',
            eventServiceUnavailable:
                'Das System steht momentan nicht zur Verfügung. Versuche es bitte später erneut.',
            rsvpTimeOut: 'Leider ist deine Anmeldung abgelaufen. Melde dich bitte erneut an.',
            waitlistRsvpTimeOut: 'Leider ist dein Wartelisten-Angebot verfallen.',
            ticketConfirmationFailed:
                'Das Event-Ticket konnte nicht bestätigt werden. Überprüfe bitte die angegebenen Informationen und versuche es erneut.',
            waitlistEntriesCreationFailed:
                'Deine Wartelisten-Anmeldung ist fehlgeschlagen. Prüfe bitte die angegebenen Informationen und versuche es erneut.',
            serviceError: {
                GP0009: 'Keine Termine verfügbar im gesuchten Zeitraum.',
                GP0010: 'Du bist bereits angemeldet. Du kannst dich nicht für mehr als eine Aktivität im selben Zeitraum oder für dieselbe Aktivität mehrmals am selben Tag anmelden.',
                GP0011: '',
                GP0013: 'Die Anmeldung für diese Aktivität hat noch nicht begonnen.',
                GP0014: 'Die Anmeldung für diese Aktivität ist bereits beendet.',
                GP0015: 'Keine zu stornierende Anmeldung gefunden.',
                GP0016: 'Der Zeitraum steht nicht mehr zur Verfügung.',
                GP0243: 'Du wurdest bereits für ein Ticket dieses Events hinzugefügt.',
                GP0244: 'Du nimmst bereits an der Lotterie für dieses Event teil.',
                GP0252: 'Du besitzt bereits einen Warp Pipe Pass für diese Aktivität.',
                unknown: '',
            },
            serviceErrorModal: {
                GP212: {
                    title: 'Anmeldung nicht möglich',
                    body: 'Anmeldungen für dieses Ticket sind noch nicht möglich.',
                },
                GP213: {
                    title: 'Anmeldung beendet',
                    body: 'Anmeldungen für dieses Ticket sind nicht mehr möglich.',
                },
                GP0214: {
                    title: 'Event-Ticket nicht verfügbar',
                    body: 'Leider ist das von dir ausgewählte Event-Ticket komplett ausgebucht. Prüfe bitte die Verfügbarkeit anderer Tickets.',
                },
                GP0218: {
                    title: 'Nicht verfügbar',
                    body: 'Du nimmst bereits an der Lotterie für dieses Ticket teil.',
                },
                GP220: {
                    title: 'Anmeldung nicht möglich',
                    body: 'Anmeldungen für dieses Ticket sind noch nicht möglich.',
                },
                GP221: {
                    title: 'Anmeldung beendet',
                    body: 'Anmeldungen für dieses Ticket sind nicht mehr möglich.',
                },
                unknown: {
                    title: 'Unbekannter Fehler',
                    body: 'Ups! Ein unbekannter Fehler ist aufgetreten. Versuche es bitte erneut.',
                },
            },
            formError: {
                required: '{attribute} erforderlich',
                default: '{attribute} unzulässig',
            },
            drawingCancelingError: 'Fehler beim Zurückziehen des Lotterieloses',
            ticketCancelingError: 'Fehler bei Ticketstornierung',
            timeSlotCancelingError: 'Fehler bei Passstornierung',
            minNumberOfGuestsError:
                'Füge bitte für eine erfolgreiche Anmeldung {atLeastNum} Mitglied/er der Nintendo-Account-Familiengruppe hinzu.',
            atLeast: 'mindestens {expectedNumberOfGuests}',
            waitlist: {
                maxNumberOfGuestsError:
                    'Bitte füge für die Anmeldung noch bis zu {maxGuestAmount} Nintendo-Account-Familiengruppenmitglieder an.',
                noTicketsSelectedError:
                    'Wähle bitte mindestens ein Event-Ticket aus, um fortzufahren.',
                claimTempTicketsError: 'Fehler beim Anfordern von Wartelisten-Tickets',
                passWaitlistError: 'Fehler beim Ablehnen von Wartelisten-Tickets',
            },
        },
        drawing: {
            drawing: 'Lotterie',
            firstComeFirstServed: 'Wer zuerst kommt, mahlt zuerst',
            enterForAChance: 'Für Lotterieteilnahme eintragen',
            youHaveAlreadyEntered: 'Du nimmst bereits an der Lotterie für diese Aktivität teil.',
            drawingRules: 'Lotterie-Regeln',
            ruleX: 'Regel: {rule}',
            status: {
                pending: 'Ausstehende Lotterie ({partySize} {type})',
                notSelected: '',
                notWinner: 'Nicht ausgewählt',
                winner: 'Ausgewählt',
            },
        },
        account: {
            accountRequired: 'Nintendo-Account erforderlich',
            pleaseLogin: {
                basic: 'Bitte mit deinem Nintendo-Account {0}.',
                toView: 'Bitte mit deinem Nintendo-Account {0}, um deine/n {1} anzusehen.',
                toAccept:
                    'Bitte mit deinem Nintendo-Account {0}, um die Teilnahmebedingungen zu akzeptieren.',
            },
            requiredToRSVP:
                'Für die Anmeldung ist ein Nintendo-Account erforderlich. Melde dich bitte mit deinem Konto an oder erstelle kostenlos ein Konto, um fortzufahren.',
            rsvpWillBeHeld: '',
            time: '10 Minuten',
            checkIn: {
                title: '',
                presentCode: '',
                poorInternetConnectivity:
                    'Für den Fall, dass bei deinem Event eine schlechte Internetverbindung bestehen sollte, empfehlen wir, diesen Pass auszudrucken, entweder von der Seite hier oder aus der E-Mail, die wir an die mit deinem Nintendo-Account verknüpfte E-Mail-Adresse gesendet haben. (Falls du diese E-Mail nicht finden kannst, prüfe bitte deinen Spam-Ordner.)',
                checkInCode: '',
                appleWalletTM:
                    'Apple Wallet is a trademark of Apple Inc., registered in the U.S. and other countries and regions.',
            },
            passes: {
                warpPipePass: 'Warp Pipe Pass',
                warpPipePasses: 'Warp Pipe Passes',
            },
            rsvps: {
                activeRSVPs: '',
                drawingEntries: '',
                rsvps: 'Tickets und Warp Pipe Passes',
                viewPass: 'Pass ansehen',
                reservationUpdated: '{0} wurde aktualisiert.',
                reservationRemoved: '{0} wurde entfernt.',
                ticketAdded: 'Du hast dein {ticketName} erhalten.',
                drawingTicketAdded: 'Du wirst an der Lotterie für {ticketName} teilnehmen.',
                warpPipePassAdded: 'Du hast deinen Pass für {ticketName} erhalten.',
                waitlistAdded: 'Du wurdest in die Warteliste für {eventName} eingetragen.',
                waitlistsUpdated: 'Deine Wartelisten-Auswahl für {eventName} wurde aktualisiert.',
                edit: {
                    title: 'Termin ändern',
                },
                cancel: {
                    title: '',
                    entry: 'Lotterielos zurückziehen',
                    pass: '',
                    ticket: 'Ticket stornieren',
                    areYouSure: '',
                },
                details: {
                    pass: {
                        ios: require('../images/pass/ios/de/apple_wallet_badge_de.svg'),
                    },
                    noneFound: '',
                    requiredForCheckIn: 'Für den Check-in ist dieser Warp Pipe Pass erforderlich.',
                    addToCalendar: 'Zum Kalender hinzufügen',
                },
            },
        },
        noneFound: {
            events: 'Im Moment stehen keine Events zur Anmeldung zur Verfügung. Versuche es bitte später erneut. Vielen Dank für dein Verständnis.',
            activities: 'Im Moment stehen keine Aktivitäten für dieses Event zur Verfügung.',
            timeSlots: 'Für diese Aktivität wurden keine Termine gefunden.',
            reservations: '',
            warpPipePasses: 'Du besitzt momentan keinen aktiven Warp Pipe Pass.',
            tickets: 'Du besitzt momentan kein aktives Event-Ticket.',
        },
        footer: {
            disclaimer:
                'Pass und/oder Ticket sind nur für den einmaligen Gebrauch, nicht übertragbar und unverkäuflich. Pass und/oder Ticket können eingelöst werden für den vorrangigen Zugang zur Warteschlange für ein ausgewähltes Spiel oder Event zu einem festgelegten Zeitpunkt und sind nicht einlösbar bei Verwendung außerhalb der festgelegten Zeit. „QR Code“ ist eine eingetragene Marke von DENSO WAVE INCORPORATED. Nintendo-Marken sind Eigentum von Nintendo. © Nintendo.',
            privacyPolicy: 'Datenschutzrichtlinie',
            privacyPolicyUrl:
                'https://www.nintendo.com/de-de/Rechtliche-Informationen/Webseiten-Datenschutzerklarung/Webseiten-Datenschutzerklarung-637785.html',
            termsOfUse: 'Nutzungsbedingungen',
            termsOfUseUrl: '',
        },
        dates: {
            fullDate: 'ddd, DD. MMMM YYYY - HH:mm',
            dateRangeFirst: 'DD. MMM',
            dateRangeSecond: 'DD. MMM YYYY',
            hourMinute: 'HH:mm',
            hourMinutesTimezone: 'H:mm',
            monthDateYear: 'DD. MMM YYYY',
            monthDateYearLong: 'DD. MMMM YYYY',
            reservationDate: 'dddd, D. MMM YYYY',
            reservationDateShort: 'ddd, D. MMM YYYY',
            reservationTime: 'H:mm',
            registrationDate: 'ddd DD. MMM YYYY HH:mm',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'H:mm',
                timeSlotHourMinute: 'H:mm',
            },
            eventTickets: {
                dateRange: 'ddd, DD. MMM YYYY',
                time: 'H:mm',
                same_day_time: 'HH:mm',
                timeZone: '',
                cancel: {
                    time: 'dddd D. MMM YYYY - HH:mm',
                },
                waitlistClaimBy: 'H:mm [um] dddd D. MMMM',
            },
            birthday: 'D. MMMM YYYY',
            formatting: {
                ISO8601ToTimezone: '',
            },
        },
        countries: {
            title: 'Land: {0}',
            values: {
                ALL: 'Alle',
                US: 'Vereinigte Staaten',
                CA: 'Kanada',
                // JP: 'Japan',
                AU: 'Australien',
                GB: 'Vereinigtes Königreich & Irland',
                DE: 'Deutschland',
                FR: 'Frankreich',
                ES: 'Spanien',
                IT: 'Italien',
                NL: 'Niederlande',
                // PT: 'Portugal',
                // BE: 'Belgien',
                // CH: 'Schweiz',
                // AT: 'Österreich',
                MX: 'Mexiko',
            },
        },
        locales: {
            en_US: 'Englisch (USA)',
            en_GB: 'Englisch (Großbritannien)',
            en_AU: 'Englisch (Australien)',
            de_DE: 'Deutsch',
            fr_FR: 'Französisch (Frankreich)',
            fr_CA: 'Französisch (Kanada)',
            it_IT: 'Italienisch',
            es_ES: 'Spanisch (Spanien)',
            es_MX: 'Spanisch (Mexiko)',
            nl_NL: 'Niederländisch',
            // pt_PT: 'Portugiesisch',
        },
        files: {
            fileCategory: {
                GENERAL_INFO: 'Allgemeine Informationen',
                LEGAL: 'Rechtshinweis',
                TOURNAMENT_INFO: 'Turnierinformation',
            },
        },
    },
    en_US,
);

export default de_DE;
