// British English (Language-Country Code: en-GB) Locale File

import defaultsDeep from 'lodash.defaultsdeep';

import en_US, { PublicLocalizationObject } from './en_US';

const en_GB: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            soldOut: 'FULLY BOOKED',
            currentLanguage: 'English (UK)',
        },
        events: {
            details: {
                accessCode: {
                    inputPlaceHolder: '16-digit access code',
                    success: {
                        woohoo: 'Woo-hoo!',
                    },
                    error: {
                        registrationClosed: 'Registration for this ticket has closed.',
                    },
                },
                waitlist: {
                    available: 'Waitlist available:',
                    drawingOver:
                        "The draw is over, but you might still have an opportunity to attend! Join the waitlist and we'll let you know if a spot opens up. Please note that joining the waitlist does not guarantee that you will receive tickets.",
                    drawingLost:
                        "Your entry for tickets was not selected for this event. If you'd like, you can join the waitlist and we'll notify you when a spot becomes available. Please note that joining the waitlist does not guarantee that you will receive tickets.",
                    waitlistJoined: "You're on the waitlist for {eventName}",
                    waitlistOffer:
                        "Hooray! An offer is available for one of your waitlist entries. You have until {monthDay} at {time} to claim it, otherwise it'll be offered to the next person on the waitlist.",
                    noLongerOnTheWaitlist:
                        'You are no longer on the waitlist for {eventName}. If you want, you can join the waitlist again.',
                },
            },
            dataAttribute: {
                MAILING_ADDRESS: {
                    zipCode: 'ZIP/Postal Code',
                },
            },
            ageRatingUrl: 'https://pegi.info/',
        },
        activities: {
            details: {
                ticketIsRequired:
                    'A ticket to this event is required before signing up for this activity. {link}.',
                eventTicketRequired: 'Event ticket required',
                ticketIsPendingDrawing:
                    'Your event ticket is still pending a draw. Check back again after the draw has occurred.',
                checkForTicketAvailability: 'Check here for ticket availability',
            },
        },
        reservations: {
            timeRemainingForReservation:
                'Time remaining: {timeRemaining}. If you leave this page or if time runs out before you complete the registration process, this transaction will be cancelled.',
            timeRemainingForDrawing: 'You have {timeRemaining} to complete this draw entry.',
            timeRemainingForWaitlist:
                "Woo-hoo! You have a waitlist offer for {numTickets} {ticketName}. If you'd like to attend, please claim the tickets before the time expires: {timeRemaining}",
            waitlistThingsToKeepInMindItem1:
                'If you claim these tickets, you will be taken off the waitlist for all other {eventName} tickets.',
            waitlistThingsToKeepInMindItem2:
                "You won't lose your offer if you leave this registration page. You'll still have until the designated date to claim the ticket(s).",
            confirmEntry: 'Enter Draw',
            adultAcknowledgement:
                'By checking this box I certify that I, and the guest(s) aged 18 or above in my family group, have read the Privacy Policy and accept the terms and conditions linked below.',
            childAcknowledgement:
                'By checking this box, I certify that I am the parent/legal guardian of the guest(s) aged under 18 in my family group. I acknowledge the Privacy Policy and accept the terms and conditions linked above on their behalf.',
            adultGuestsAcknowledgement:
                'By clicking this box, I confirm that I have my adult guests’ consent to add them as guests for this event and to receive an email invitation. Each adult guest must agree to the terms and conditions attached to the invitation email before attending the event.',
            event_registration: {
                attendeeCannotRegister:
                    'Attendees cannot register for more than one activity during the same time slot, or for the same activity more than once on the same day.',
                currentUserIneligible:
                    'You are already registered for a Warp Pipe Pass for this activity or another activity at the same time.',
                removedGuests:
                    '{guests} has been removed as a guest because they have already registered for a Warp Pipe Pass for this activity or another activity at the same time.',
            },
        },
        tickets: {
            soldOut: 'Fully Booked',
            adultRequiredTooltip: 'This event can only be registered by an adult.',
            adultRequiredActivityTooltip: 'This activity can only be registered by an adult.',
            registrationNotStarted: 'Registration opens on {0} at {1}',
            drawings: {
                wonDrawing: 'You won the ticket draw for {resourceName} {resourceDates}.',
                lostDrawing: 'Sorry, but you did not win the draw for this ticket.',
                pendingDrawing:
                    'You have entered the ticket draw for {resourceName} {resourceDates}.',
            },
            registration: {
                registrant: {
                    onlyOneActivity:
                        '{note} Attendees cannot register for more than one activity during the same time slot, or for the same activity more than once on the same day.',
                },
                guest: {
                    manageNintendoAccountFamily: 'Manage Nintendo Account Family Group',
                    guestsRemovedDrawing:
                        '{guests} has been removed as a guest because they have already entered the draw for a ticket for this event.',
                    maxLimit: 'There can be a maximum of {max} guest(s).',
                    guestFamilyHelpUrl:
                        'https://www.nintendo.com/en-gb/Support/Nintendo-Switch/How-to-Add-Members-to-a-Family-Group-1406409.html',
                    guestFamilyUrl:
                        'https://www.nintendo.com/en-gb/Support/Nintendo-Switch/How-to-Add-Members-to-a-Family-Group-1406409.html',
                    reachedMaxGuests: "You've reached the maximum limit for guests.",
                    childAndNotParent: 'They are a child and you are not the parent/guardian.',
                    alreadyRegistered: 'They are already registered.',
                },
            },
            cancelTicket: {
                cancelTicketModalWarning: {
                    line1: 'You will be unable to attend this event without a ticket.',
                    line2: 'Your ticket and your Warp Pipe Pass(es) will be cancelled.',
                    line3: 'Cancelling your ticket will automatically cancel the ticket(s) and Warp Pipe Pass(es) for everyone in your group (if applicable). Everyone will receive a cancellation confirmation email.',
                    line4: 'All cancellations are final. Tickets and Warp Pipe Passes will not be honoured if cancelled.',
                },
                imSure: "Yes, I'm sure",
            },
            cancelPass: {
                imSure: "Yes, I'm sure",
            },
            cancelDrawingEntry: {
                cancelTicketModalWarning: {
                    line1: 'You will be removed from the draw for this ticket.',
                    line2: 'Cancelling your draw entry will automatically cancel the draw entry for everyone in your group (if applicable).  Everyone will receive a cancellation confirmation email.',
                    line3: '',
                    line4: '',
                },
                areYouSure: 'Are you sure you want to cancel this entry?',
                imSure: "Yes, I'm sure",
            },
            cancelPassDrawingEntry: {
                cancelTicketModalWarning: {
                    line1: 'You will be removed from the draw for this ticket.',
                    line2: 'Cancelling your draw entry will automatically cancel the draw entry for everyone in your group (if applicable).  Everyone will receive a cancellation confirmation email.',
                    line3: '',
                    line4: '',
                },
                areYouSure: 'Are you sure you want to cancel this entry?',
                imSure: "Yes, I'm sure",
            },
            waitlist: {
                notice: {
                    line1: '{notice}: Tickets will be offered on a first come, first served basis for each ticket, according to when applicants joined the waitlist, and based on the quantity of tickets requested and which tickets become available. ',
                    line2: 'You can only receive one offer at a time.',
                    line3: 'You will have {timeFrame} to register for the event before the offer expires.',
                    line4: 'If you claim an offer, you will be removed from the waitlist for any remaining tickets.',
                },
                summaryFooter:
                    "You are about to submit a request to join the waitlist for {eventName}. If you are offered tickets, you will be notified via email. To make sure you continue receiving emails from us, please add our 'From' email address ({emailAddress}) to your address book. Please also keep an eye on your spam folder. You'll have 36 hours to claim an offer or the opportunity will expire.",
                confirmRemoveSelfFromWaitlist:
                    'By cancelling your entry, you will remove yourself from the waitlist for {ticketName} and will not be notified if additional tickets become available.',
                removeWaitlistTicketsWarning:
                    'By cancelling your entry, you will remove yourself from the waitlist for any unselected tickets and will not be notified if additional tickets become available.',
            },
            appleWalletAddButtonAlt: 'Add to Apple Wallet button',
        },
        terms: {
            addedToDrawing:
                'You have been added to a draw to receive a ticket to the following Nintendo event by {invitor}. In order to be eligible for the draw you must accept the event terms.',
            addedToEvent:
                'You have received a ticket to the following Nintendo event from {invitor}. In order to be eligible for the event you must accept the event terms.',
        },
        error: {
            warning: 'Please note',
            rsvpTimeOut: 'Sorry, but your registration has expired. Please try registering again.',
            waitlistRsvpTimeOut: "We're sorry, but your waitlist offer has expired.",
            ticketConfirmationFailed:
                'Event ticket confirmation has failed. Please check the entered details and try again.',
            waitlistEntriesCreationFailed:
                "Your waitlist entry wasn't successful. Please check the entered details and try again.",
            serviceError: {
                GP0010: 'You have already registered. You cannot register for more than one activity during the same time slot, or for the same activity more than once on the same day.',
                GP0244: 'You have already been entered into the draw for this event.',
                unknown: 'An error occurred',
            },
            serviceErrorModal: {
                GP0214: {
                    body: 'Sorry, the event ticket you have selected is fully booked. Please check for other ticket availability.',
                },
                GP0218: {
                    body: 'You are already entered into a draw for this ticket.',
                },
            },
            drawingCancelingError: 'Error cancelling entry into draw',
            ticketCancelingError: 'Error cancelling ticket',
            timeSlotCancelingError: 'Error cancelling pass',
        },
        drawing: {
            drawing: 'Draw',
            firstComeFirstServed: 'First come, first served',
            youHaveAlreadyEntered: 'You have already entered the draw for this activity.',
            drawingRules: 'Draw Rules',
            status: {
                pending: 'Pending Draw ({partySize} {type})',
                notWinner: 'Not Selected',
                winner: 'Selected',
            },
        },
        account: {
            pleaseLogin: {
                toAccept: 'Please {0} to your Nintendo Account to accept the terms.',
            },
            checkIn: {
                poorInternetConnectivity:
                    'In case there is poor internet connectivity at your event, we recommend that you print out this pass either from this page or from the email we have sent to the email address associated with your Nintendo Account. (If you do not see this email, please check your spam folder).',
            },
            rsvps: {
                drawingTicketAdded: 'You have successfully entered the draw for {ticketName}.',
            },
        },
        footer: {
            privacyPolicyUrl:
                'https://www.nintendo.com/en-gb/Legal-information/Website-Privacy-Policy/Website-Privacy-Policy-637785.html',
            termsOfUseUrl: '',
        },
        dates: {
            fullDate: 'ddd DD MMMM YYYY - H:mm',
            dateRangeFirst: 'Do MMM',
            dateRangeSecond: 'DD MMM YYYY',
            hourMinute: 'H:mm',
            hourMinutesTimezone: 'H:mm',
            monthDateYear: 'DD MMM YYYY',
            monthDateYearLong: 'DD MMMM YYYY',
            reservationDate: 'dddd D MMM YYYY',
            reservationDateShort: 'ddd D MMM YYYY',
            reservationTime: 'H:mm',
            registrationDate: 'ddd DD MMM YYYY HH:mm',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'HH:mm',
                timeSlotHourMinute: 'HH:mm',
            },
            eventTickets: {
                dateRange: 'ddd DD MMM YYYY',
                time: 'H:mm',
                same_day_time: 'HH:mm',
                timeZone: '',
                cancel: {
                    time: 'dddd D MMM YYYY - HH:mm',
                },
                waitlistClaimBy: 'H:mm [on] dddd Do MMMM',
            },
            birthday: 'D MMMM YYYY',
            formatting: {
                ISO8601ToTimezone: '',
            },
        },
    },
    en_US,
);

export default en_GB;
