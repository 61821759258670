// French (Language-Country Code: fr-FR) Locale File.
import defaultsDeep from 'lodash.defaultsdeep';

import en_US, { PublicLocalizationObject } from './en_US';

const fr_FR: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            backToActivityTimes: "Retour aux horaires de l'activité",
            backToActivityDetails: "Retour aux détails de l'activité",
            backToEvent: "Retour à l'événement",
            location: 'Lieu',
            rsvp: '',
            entry: '',
            soldOut: 'COMPLET',
            cancel: 'Annuler',
            close: 'Fermer',
            confirm: 'Confirmer',
            exit: '',
            signInCreateAccount: 'Se connecter / Créer un compte',
            signIn: 'vous connecter',
            warpPipePass: '',
            learnMore: "Plus d'infos",
            currentLanguage: 'Français (France)',
            yes: '',
            no: '',
            note: 'REMARQUE ',
            addressFormat: '{address}, {city}, {region} {postal_code}',
            addressFormatHideRegion: '{address}, {city} {postal_code}',
            areYouSure: 'Voulez-vous vraiment continuer ?',
        },
        tabs: {
            events: 'Événements',
            myRSVPs: '',
            myTicketsAndPasses: 'Mes billets / Pass',
            checkIn: '',
            signIn: '',
        },
        events: {
            eventItemHeader: 'Événement :',
            upcoming: '',
            moreInfo: "Plus d'infos",
            eventDetails: "Détails de l'événement",
            viewEventDetails: "Voir les détails de l'événement",
            details: {
                tabs: {
                    tickets: 'Billets',
                    activities: 'Activités',
                    resources: '',
                },
                accessCode: {
                    aria: {
                        accessCodeInput: "Code d'accès",
                        exitButton: 'Quitter le pavé numérique virtuel',
                    },
                    title: "Entrez votre code d'accès",
                    toggleButtonLabel: "Enregistrer un code d'accès",
                    redeemButtonLabel: 'Enregistrer',
                    inputPlaceHolder: "Code d'accès à 16 chiffres",
                    enterDifferentCode: "Entrer un code d'accès différent",
                    success: {
                        woohoo: "C'est fait !",
                        youCanRegister: 'Vous pouvez désormais vous inscrire pour :',
                    },
                    error: {
                        invalid: 'Code incorrect. Veuillez essayer à nouveau.',
                        registrationClosed: 'Les inscriptions pour ce billet sont terminées.',
                    },
                },
                waitlist: {
                    available: "Liste d'attente disponible :",
                    drawingOver:
                        "le tirage est terminé, mais vous avez peut-être encore la possibilité de participer ! Inscrivez-vous sur la liste d'attente et nous vous informerons si une place se libère. Notez que l'inscription sur la liste d'attente ne garantit pas l'obtention de billets.",
                    drawingLost:
                        "vous n'avez pas obtenu de billets pour cet événement. Si vous le souhaitez, vous pouvez vous inscrire sur la liste d'attente et nous vous informerons si une place se libère. Notez que l'inscription sur la liste d'attente ne garantit pas l'obtention de billets.",
                    waitlistJoined: "Vous êtes sur la liste d'attente pour {eventName}",
                    waitlistLink: "Voir votre liste d'attente >",
                    waitlistOffer:
                        "Hourra ! Vous avez reçu une offre de billets pour un événement dont vous êtes sur liste d'attente. Vous avez jusqu'au {monthDay} à {time} pour l'accepter, après quoi elle sera proposée à la personne suivante sur la liste d'attente.",
                    offerPassed:
                        "Vous avez refusé l'offre pour {ticketName}. Si vous le souhaitez, vous pouvez vous réinscrire sur la liste d'attente.",
                    offerExpired:
                        "L'offre pour {ticketName} a expiré. Si vous le souhaitez, vous pouvez vous réinscrire sur la liste d'attente.",
                    waitlistClosed: "La liste d'attente est fermée.",
                    button: {
                        join: "S'inscrire sur la liste d'attente",
                        edit: "Éditer votre liste d'attente",
                    },
                },
            },
            dataAttribute: {
                FULL_NAME: 'Nom légal',
                FIRST_NAME: 'Prénom',
                LAST_NAME: 'Nom de famille',
                POSTAL_CODE: 'Code postal',
                PHONE: 'Numéro de téléphone',
                US_ADDRESS: 'Adresse postale',
                MAILING_ADDRESS: {
                    streetAddress: 'Adresse',
                    aptOrSuite: "Complément d'adresse",
                    city: 'Ville',
                    state: 'Département / Province / Canton',
                    country: 'Pays',
                    zipCode: 'Code postal',
                },
                OPT_IN: "Consentir au partage d'e-mail",
                ACCESS_CODE: "Code d'accès",
            },
            ageRatingUrl: 'https://pegi.info/fr',
        },
        activities: {
            activities: 'Activités',
            viewActivities: 'Voir les activités',
            registrationNotBegun: "Il n'est pas encore possible de s'inscrire pour cette activité.",
            registrationHasEnded: "Il n'est plus possible de s'inscrire pour cette activité.",
            registrationBegins: 'Début des inscriptions :',
            details: {
                title: "Détails de l'activité",
                registrationBegins: 'Début des inscriptions :',
                registrationEnds: 'Fin des inscriptions :',
                header: {
                    noAvailableTimes: "Toutes nos excuses, il n'y a plus de créneau disponible.",
                },
                continueToRSVP: 'Continuer',
                viewMy: 'Voir mon {0}',
                alreadyRSVPd: 'Vous possédez déjà un Warp Pipe Pass pour cette activité.',
                ticketIsRequired:
                    "Vous devez posséder un billet pour l'événement pour vous inscrire à cette activité. {link}.",
                eventTicketRequired: "Billet pour l'événement requis",
                ticketIsPendingDrawing:
                    "Le tirage des billets n'a pas encore été effectué. Veuillez revenir une fois le tirage effectué.",
                checkForTicketAvailability: 'Vérifiez ici la disponibilité des billets',
                label: {
                    startDate: 'Date de début : {startDate}',
                    activityType: "Type d'activité : {activityType}",
                },
                iconLabel: {
                    activityTime: "Icône de l'horaire de l'activité",
                    activityType: "Icône du type de l'activité",
                    registration: "Icône du type d'inscription",
                    location: 'Icône du lieu',
                },
            },
            timeSlots: {
                title: '',
                noTimesForDay: "Il n'y a pas de créneau horaire disponible pour cette journée.",
            },
            unknownActivityType: '',
            types: {
                GAME_DEMO: 'Démo de jeu',
                GENERAL_CHECK_IN: 'Enregistrement général',
                COMPETITIVE_PLAY: 'Jeu compétitif',
                EXCLUSIVE_OFFER: 'Offre exclusive',
                LEAD_GENERATION: 'Génération de leads',
                MEET_GREET: 'Rencontre et dédicaces',
                PRODUCT_LAUNCH: 'Lancement de produit',
                SPECIAL_EVENT: 'Événement spécial',
                unknown: '',
            },
        },
        reservations: {
            timeRemainingForReservation:
                "Temps restant : {timeRemaining}. Si vous quittez cette page ou si le temps imparti est écoulé avant la fin du processus d'inscription, cette opération sera annulée.",
            timeRemainingForDrawing:
                'Il vous reste {timeRemaining} pour confirmer votre participation au tirage.',
            timeRemainingForWaitlist:
                "Bravo ! Vous êtes éligible pour {numTickets} {ticketName} pour un événement pour lequel vous étiez sur liste d'attente. Si vous souhaitez y assister, veuillez accepter vos billets avant l'expiration du délai : {timeRemaining}",
            waitlistThingsToKeepInMind: "Quelques éléments à garder à l'esprit :",
            waitlistThingsToKeepInMindItem1:
                "Si vous acceptez ces billets, vous quitterez les listes d'attente pour tous les autres billets de l'événement {eventName}.",
            waitlistThingsToKeepInMindItem2:
                "L'offre ne sera pas perdue si vous quittez cette page. Vous aurez toujours jusqu'à la date indiquée pour accepter le(s) billet(s).",
            waitlistThingsToKeepInMindItem3:
                "Refuser des billets n'affectera pas vos autres inscriptions sur la liste d'attente pour {eventName} le cas échéant. Vous pourrez par ailleurs vous réinscrire sur la liste d'attente ultérieurement si vous le souhaitez.",
            waitlistThingsToKeepInMindItem4:
                "Décliner des billets n'affectera pas vos autres inscriptions sur la liste d'attente pour {eventName} le cas échéant. Vous pourrez par ailleurs vous réinscrire sur la liste d'attente ultérieurement si vous le souhaitez.",
            minutes: ' minutes',
            registrant: '',
            registrantInformation: "Vos informations d'inscription",
            nickname: 'Surnom du compte Nintendo',
            confirmRSVP: 'Confirmer',
            confirmEntry: 'Participer au tirage',
            legalConsent: 'Consentement légal',
            adultAcknowledgement:
                'En cochant cette case, je certifie que moi-même, ainsi que la ou les personnes invitées âgées de 18 ans ou plus de mon groupe familial, avons lu la Politique de confidentialité et accepté les conditions générales en lien ci-dessous.',
            childAcknowledgement:
                'En cochant cette case, je certifie que je suis le/la parent(e) ou tuteur/tutrice légal(e) de la ou des personnes invitées âgées de moins de 18 ans de mon groupe familial. Je certifie aussi avoir pris connaissance de la Politique de confidentialité et avoir accepté les conditions générales en lien ci-dessus en son/leur nom.',
            adultGuestsAcknowledgement:
                "En cochant cette case, je certifie que les personnes majeures que j'ai ajoutées ont consenti à être invitées à cet événement, afin qu'elles reçoivent une invitation par e-mail. Chaque invité majeur doit accepter les conditions générales jointes à l'e-mail d'invitation avant de participer à l'événement.",
            event_registration: {
                attendees: 'Participants',
                note: 'REMARQUE :',
                attendeeCannotRegister:
                    "les participants ne peuvent s'inscrire ni à plusieurs activités durant le même créneau horaire, ni à la même activité plus d'une fois dans la même journée.",
                currentUserIneligible:
                    'Vous êtes déjà inscrit(e) pour un Warp Pipe Pass donnant accès à cette activité ou à une autre activité au même moment.',
                removedGuests:
                    "{guests} a été retiré(e) de la liste d'invités car il/elle est déjà inscrit(e) pour un Warp Pipe Pass donnant accès à cette activité ou à une autre activité au même moment.",
                selectAllAttendees:
                    'Sélectionnez toutes les personnes qui participeront à cette activité.',
            },
        },
        tickets: {
            ticket: 'billet',
            tickets: 'billets',
            wppRequiredForCheckIn: 'Ce {0} est nécessaire pour vous enregistrer.',
            wppsRequiredForCheckIn: 'Ces {0} sont nécessaires pour vous enregistrer.',
            ticketRequiredForCheckIn: "Ce {0} est nécessaire pour assister à l'événement.",
            ticketsRequiredForCheckIn: "Ces {0} sont nécessaires pour assister à l'événement.",
            getTickets: 'Obtenir des billets',
            eventTicket: 'billet',
            eventTickets: 'billets',
            viewTickets: 'Voir vos billets',
            viewEntry: 'Voir participation',
            registrationClosed: 'Inscriptions terminées',
            comingSoon: 'Bientôt disponible',
            cancelOtherTicket:
                'Veuillez annuler votre autre billet avant de vous inscrire pour celui-ci.',
            soldOut: 'Complet',
            adultRequired: 'Adulte requis',
            adultRequiredTooltip:
                "L'inscription à cet événement ne peut être effectuée que par un adulte.",
            adultRequiredActivityTooltip:
                "L'inscription à cette activité ne peut être effectuée que par un adulte.",
            backToMyTicketsPasses: 'Retour à Mes billets / Pass',
            registrationAlreadyClosed: 'Les inscriptions prennent fin le {0} à {1}',
            registrationNotStarted: 'Les inscriptions débutent le {0} à {1}',
            notFound: 'Billets introuvables',
            unknownTicketType: 'Billet de type inconnu',
            consent: {
                consentRequiredTooltipTitle: 'Consentement requis',
                consentRequiredTooltipBody:
                    "Cette personne doit accepter l'invitation et les conditions en se connectant à son compte Nintendo.",
                consentRequiredToolTipBodyHostUrl: "accepter l'invitation",
                consentRequiredToolTipBodyHost:
                    'Vous devez {0} et accepter les conditions en vous connectant à votre compte Nintendo.',
            },
            receivedTickets: 'Vous avez reçu vos billets pour {resourceName} {resourceDates}.',
            drawings: {
                wonDrawing:
                    'Votre participation au tirage pour {resourceName} {resourceDates} a été sélectionnée.',
                lostDrawing:
                    "Toutes nos excuses, votre participation au tirage pour ce billet n'a pas été sélectionnée.",
                pendingDrawing: 'Vous participez au tirage pour {resourceName} {resourceDates}.',
            },
            registration: {
                ticketDetails: 'Détails du billet',
                registrant: {
                    onlyOneActivity:
                        "{note} les participants ne peuvent s'inscrire ni à plusieurs activités durant le même créneau horaire, ni à la même activité plus d'une fois dans la même journée.",
                    dataNotice:
                        'Pour en savoir plus sur la manière dont nous utilisons vos données, veuillez consulter notre {link}',
                    requiredFields: "Tous les champs marqués d'une * sont obligatoires",
                },
                guest: {
                    guest: 'Invité',
                    guests: 'Vos invités ({maxGuests} maximum)',
                    birthday: 'Date de naissance',
                    addManageGuests: "Ajout / Gestion d'invités",
                    manageNintendoAccountFamily:
                        'Gestion du groupe familial de votre compte Nintendo',
                    guestsRemovedTicket:
                        "{guests} a été retiré(e) de vos invités car il/elle s'est déjà inscrit(e) pour un billet pour cet événement.",
                    guestsRemovedDrawing:
                        '{guests} ont été retiré(e)s de vos invités car ils/elles se sont déjà inscrit(e)s au tirage pour cet événement.',
                    maxLimit: "Vous pouvez inviter jusqu'à {max} personne(s).",
                    guestFamilyHelp:
                        'Vous ne possédez pas de groupe familial ? {guestFamilyHelpLink}',
                    guestFamilyHelpLink: 'Découvrez comment en créer et en gérer un.',
                    guestFamilyHelpUrl:
                        'https://www.nintendo.com/fr-fr/Assistance/Nintendo-Switch/Comment-ajouter-des-membres-a-un-groupe-familial-1406409.html',
                    guestFamilyWarning:
                        "Les invités ne peuvent être ajoutés que depuis le {nintendoFamilyLink}. Si vous ne voyez pas les personnes que vous voulez inviter dans la liste, veuillez d'abord commencer par les ajouter à votre groupe familial.",
                    guestFamilyLink: 'groupe familial de votre compte Nintendo',
                    guestFamilyUrl:
                        'https://www.nintendo.com/fr-fr/Assistance/Nintendo-Switch/Comment-ajouter-des-membres-a-un-groupe-familial-1406409.html',
                    guestRemoveButtonLabel: 'Retirer {user} de votre groupe familial',
                    selectFamilyMember: 'Sélectionnez des membres du groupe familial',
                    counter: '{current} / {max} invité(s) sélectionné(s)',
                    child: 'enfant',
                    notSelectableIf:
                        'Vous ne pouvez pas sélectionner un membre du groupe familial si ',
                    reachedMaxGuests: '1. Vous avez déjà invité le nombre maximum de personnes.',
                    childAndNotParent:
                        "2. Il s'agit d'un enfant et que vous n'êtes pas son parent ou tuteur/tutrice légal(e).",
                    alreadyRegistered: "3. Il ou elle s'est déjà inscrit(e).",
                },
            },
            cancelTicket: {
                cancelTicketModalTitle: 'Annuler un billet',
                cancelLink: 'Annuler ce billet',
                cancelTicketModalWarning: {
                    line1: 'Vous ne pourrez pas assister à cet événement sans billet.',
                    line2: 'Votre billet et votre/vos Warp Pipe Pass seront annulés.',
                    line3: "L'annulation de votre billet annulera automatiquement le ou les billets et Warp Pipe Pass pour tous les membres de votre groupe (le cas échéant). Tout le monde recevra un e-mail de confirmation d'annulation.",
                    line4: "Toutes les annulations sont définitives. Les billets et les Warp Pipe Pass ne seront pas honorés en cas d'annulation.",
                },
                areYouSure: 'Voulez-vous vraiment annuler ce billet ?',
                imSure: 'Oui',
                cancelButton: 'Annuler le billet',
                cancelYourTicket: 'Vous ne pourrez pas être là ? Annulez votre/vos billet(s)',
            },
            cancelPass: {
                cancelTicketModalTitle: 'Annuler un Pass',
                cancelLink: 'Annuler ce Pass',
                areYouSure: 'Voulez-vous vraiment annuler ce Warp Pipe Pass ?',
                imSure: 'Oui',
                cancelButton: 'Annuler le Pass',
            },
            cancelDrawingEntry: {
                cancelTicketModalTitle: 'Annuler la participation au tirage',
                cancelLink: '',
                cancelTicketModalWarning: {
                    line1: 'Vous quitterez le tirage pour ce billet.',
                    line2: "L'annulation de votre participation au tirage annulera automatiquement les participations de tous les membres de votre groupe (le cas échéant). Tout le monde recevra un e-mail de confirmation d'annulation.",
                    line3: '',
                    line4: '',
                },
                areYouSure: 'Voulez-vous vraiment annuler votre participation ?',
                imSure: 'Oui',
                cancelButton: 'Annuler la participation au tirage',
            },
            cancelPassDrawingEntry: {
                cancelTicketModalTitle: 'Annuler la participation au tirage',
                cancelLink: '',
                cancelTicketModalWarning: {
                    line1: 'Vous quitterez le tirage pour ce billet.',
                    line2: "L'annulation de votre participation au tirage annulera automatiquement les participations de tous les membres de votre groupe (le cas échéant). Tout le monde recevra un e-mail de confirmation d'annulation.",
                    line3: '',
                    line4: '',
                },
                areYouSure: 'Voulez-vous vraiment annuler votre participation ?',
                imSure: 'Oui',
                cancelButton: 'Annuler la participation au tirage',
            },
            waitlist: {
                waitlist: "Liste d'attente",
                title: "S'inscrire la liste d'attente pour {eventName}",
                editTitle: "Modifier la liste d'attente pour {eventName}",
                subtitle: 'Nous vous informerons si une place se libère !',
                notice: {
                    line1: "{notice} : les billets sont proposés suivant le principe du premier arrivé, premier servi, selon la date de l'inscription sur la liste d'attente, du nombre de billets demandés et de la disponibilité des billets.",
                    line2: "Vous ne pouvez bénéficier que d'une offre à la fois.",
                    line3: "Vous aurez {timeFrame} pour vous inscrire à l'événement avant expiration de l'offre.",
                    line4: "Si vous acceptez une offre, vous quitterez la liste d'attente pour toute autre demande de billets, le cas échéant.",
                    notice: 'Remarque :',
                    timeFrame: '36 heures',
                },
                individualTicketsHeader: 'Billets individuels',
                familyTicketsHeader: 'Billets famille',
                guests: 'Invités',
                individual: 'Individuels',
                family: 'Famille',
                adultGuests: 'Invités adultes (18 ans ou plus)',
                childGuests: 'Invités enfants (de 7 à 17 ans)',
                guestCount: '{currentGuests}/{maxGuests} invité(s) sélectionné(s)',
                select: "Sélectionnez la ou les listes d'attente où vous souhaitez vous inscrire",
                selectAll: 'Sélectionner tous les billets ({waitlistType})',
                submit: "S'inscrire sur la liste d'attente",
                summary: 'Récapitulatif',
                summaryRequired:
                    "Vous devez accepter les conditions générales pour pouvoir vous inscrire sur la liste d'attente.",
                summaryCheckbox:
                    "En cochant cette case, je comprends que mon inscription sur la liste d'attente ne garantit pas l'obtention de billets. Si une offre se présente, j'aurai 36 heures pour l'accepter avant qu'elle n'expire.",
                summaryFooter:
                    "Vous êtes sur le point de demander votre inscription sur la liste d'attente pour {eventName}. Si des billets vous sont proposés, vous en serez informé(e) par e-mail. Pour vous assurer de recevoir des e-mails de notre part, ajoutez notre adresse e-mail ({emailAddress}) à votre carnet d'adresses. Surveillez également votre dossier de courrier indésirable. Vous aurez 36 heures pour accepter une offre avant qu'elle n'expire.",
                summaryEmail: 'no-reply@noa.nintendo.com',
                onTheWaitlist: "Vous êtes sur la liste d'attente",
                leaveWaitlists: "Quitter les listes d'attente",
                leaveWaitlist: "Quitter la liste d'attente",
                makeChanges: 'Modifier',
                cancelEntry: "Annuler l'inscription",
                onWaitlistRequestingTickets:
                    "Sur la liste d'attente ({count} billet(s) demandé(s) pour cet événement)",
                confirmRemoveSelfFromWaitlistsForEvent:
                    "Vous êtes sur le point de vous retirer des listes d'attente pour {eventName}. Si des billets deviennent disponibles, vous ne recevrez pas de notification pour vous en informer.",
                confirmRemoveSelfFromWaitlist:
                    "En annulant votre inscription, vous vous retirez de la liste d'attente pour {ticketName}. Si des billets deviennent disponibles, vous ne recevrez pas de notification pour vous en informer.",
                stayOnWaitlists: "Rester dans les listes d'attente",
                stayOnWaitlist: "Rester dans la liste d'attente",
                noteOtherWaitlistsUnaffected:
                    "Notez que cela n'affectera pas les autres listes d'attente que vous avez rejointes.",
                congratulationsTicketWaitlistOffered:
                    'Bravo, Vous avez reçu une offre de billets pour {eventName} ! Acceptez-la avant le {claimByDate} pour réserver votre place.',
                congratulationsEventTicketWaitlistOffered:
                    'Bravo, vous avez reçu une offre de billets ! Élargir pour plus de détails.',
                claimTickets: 'Accepter les billets',
                passOnTickets: 'Refuser les billets',
                areYouSureYouDontWantIt: 'Voulez-vous vraiment refuser cette offre ?',
                passOnTicketsNotice:
                    "Si vous refusez l'offre, vous ne pourrez plus accepter les billets proposés et vous quitterez la liste d'attente.",
                keepOffer: "Accepter l'offre",
                passOnOffer: "Refuser l'offre",
                removeWaitlistTicketsWarning:
                    "si vous annulez votre inscription, vous quitterez la liste d'attente pour tous les billets non sélectionnés et vous ne recevrez pas de notification si d'autres billets deviennent disponibles.",
                updateWaitlist: "Actualiser la liste d'attente",
                theWaitlistIsClosed: "La liste d'attente est fermée.",
                youClaimed: 'Vous avez accepté {ticketsCount} pour {eventName}.',
                yourOfferHasExpired:
                    "L'offre pour {ticketName} a expiré. Si vous le souhaitez, vous pouvez vous réinscrire sur la liste d'attente.",
                youPassedOnThisOffer:
                    "Vous avez décliné l'offre pour {ticketName}. Si vous le souhaitez, vous pouvez vous réinscrire sur la liste d'attente.",
                offerExpired: 'Offre expirée',
                offerPassed: 'Offre refusée',
                noLongerOnTheWaitlist:
                    "Vous n'êtes plus sur la liste d'attente pour {eventName}. Si vous le souhaitez, vous pouvez vous inscrire à nouveau sur la liste d'attente.",
            },
            filter: {
                allDays: 'Tous les jours',
                filterTicketsLabel: 'Filtrer les billets :',
                ticketTypes: {
                    ALL: 'Tous les billets',
                    INDIVIDUAL: 'Billets individuels',
                    FAMILY: 'Billets famille',
                },
            },
            appleWalletAddButtonAlt: 'Bouton Ajouter à Cartes Apple',
        },
        terms: {
            acceptTerms: 'Accepter les conditions',
            acceptEventTerms: "Accepter les conditions de l'événement",
            addedToDrawing:
                "Vous avez été ajouté(e) à un tirage pour recevoir un billet pour l'événement Nintendo suivant par {invitor}. Pour être éligible au tirage, vous devez accepter les conditions de l'événement.",
            addedToEvent:
                "Vous avez reçu un billet pour l'événement Nintendo suivant par {invitor}. Pour être éligible au tirage, vous devez accepter les conditions de l'événement.",
            completeInformation: 'Veuillez confirmer les informations suivantes :',
            terms: '',
            viewAndAcceptTerms: 'Voir et accepter les conditions',
            pendingTermsConsent: "En attente de l'acceptation des conditions",
        },
        error: {
            error: 'Erreur',
            warning: 'Note ',
            modal: {
                close: 'Fermer',
            },
            notFound: {
                title: "Toutes nos excuses, la page ou l'événement que vous recherchez n'a pas pu être trouvé(e).",
                viewAvailableEvents: 'Voir les événements disponibles',
            },
            eventsError:
                "Une erreur s'est produite lors de la récupération des événements. Veuillez réessayer ultérieurement.",
            eventDetailsError:
                "Une erreur s'est produite lors de la récupération des détails de l'événement. Veuillez réessayer ultérieurement.",
            activityDetailsError:
                "Une erreur s'est produite lors de la récupération des détails de l'activité. Veuillez réessayer ultérieurement.",
            timeSlotError:
                "Une erreur s'est produite lors de la récupération des détails liés aux créneaux horaires. Veuillez réessayer ultérieurement.",
            tempReservationError:
                "Une erreur s'est produite lors de l'enregistrement de cette réservation. Veuillez réessayer ultérieurement.",
            myRSVPsError:
                "Une erreur s'est produite lors de la récupération de vos RSVP. Veuillez réessayer ultérieurement.",
            mapError: 'La carte ne peut pas être affichée pour le moment.',
            dataCollectionError:
                "Une erreur s'est produite lors de la récupération des événements. Veuillez réessayer ultérieurement.",
            reservationCouldNotBeConfirmed:
                "La réservation n'a pas pu être confirmée. Veuillez réessayer ultérieurement.",
            reservationCancelFailure:
                "Une erreur s'est produite lors de l'annulation. Veuillez réessayer ultérieurement.",
            reservationFormError: 'tous les champs obligatoires doivent être remplis.',
            eventServiceUnavailable:
                'Le système est actuellement indisponible. Veuillez réessayer ultérieurement.',
            rsvpTimeOut:
                'Toutes nos excuses, votre inscription a expiré. Veuillez essayer de vous inscrire à nouveau.',
            waitlistRsvpTimeOut:
                "Malheureusement, le délai d'acceptation pour cette offre a expiré.",
            ticketConfirmationFailed:
                'La confirmation du billet a échoué. Veuillez vérifier les informations saisies et essayer à nouveau.',
            waitlistEntriesCreationFailed:
                "Votre inscription sur la liste d'attente a échoué. Vérifiez les informations saisies et réessayez.",
            serviceError: {
                GP0009: "Aucune place n'est disponible dans le créneau horaire demandé.",
                GP0010: "Vous êtes déjà inscrit(e). Les participants ne peuvent s'inscrire ni à plusieurs activités dans le même créneau horaire, ni à la même activité plus d'une fois dans la même journée.",
                GP0011: '',
                GP0013: "Il n'est pas encore possible de s'inscrire pour cette activité.",
                GP0014: "Il n'est plus possible de s'inscrire pour cette activité.",
                GP0015: 'Impossible de trouver une réservation à annuler.',
                GP0016: 'Le créneau horaire a déjà pris fin.',
                GP0243: 'Vous avez déjà été ajouté(e) à un tirage pour ce billet.',
                GP0244: 'Vous avez déjà été inscrit(e) au tirage pour cette activité.',
                GP0252: 'Vous possédez déjà un Warp Pipe Pass pour cette activité.',
                unknown: "Une erreur s'est produite",
            },
            serviceErrorModal: {
                GP212: {
                    title: 'Inscriptions non ouvertes',
                    body: 'Les inscriptions pour ce billet ne sont pas encore ouvertes.',
                },
                GP213: {
                    title: 'Inscriptions terminées',
                    body: 'Les inscriptions pour ce billet sont terminées.',
                },
                GP0214: {
                    title: 'Billet non disponible',
                    body: "Toutes nos excuses, l'événement pour lequel vous avez sélectionné un billet est complet. Veuillez vérifier la disponibilité des autres billets.",
                },
                GP0218: {
                    title: 'Inéligible',
                    body: 'Vous participez déjà à un tirage pour ce billet.',
                },
                GP220: {
                    title: 'Inscriptions non ouvertes',
                    body: 'Les inscriptions pour ce billet ne sont pas encore ouvertes.',
                },
                GP221: {
                    title: 'Inscriptions terminées',
                    body: 'Les inscriptions pour ce billet sont terminées.',
                },
                unknown: {
                    title: 'Erreur inconnue',
                    body: "Toutes nos excuses, une erreur inconnue s'est produite. Veuillez essayer à nouveau.",
                },
            },
            formError: {
                required: 'Ce champ est requis.',
                default: "Ce champ n'est pas valide.",
            },
            drawingCancelingError:
                "Une erreur est survenue lors de l'annulation de la participation au tirage.",
            ticketCancelingError: "Erreur lors de l'annulation du billet",
            timeSlotCancelingError: "Erreur lors de l'annulation du Pass",
            minNumberOfGuestsError:
                'veuillez ajouter {atLeastNum} membre(s) du groupe familial pour vous inscrire.',
            atLeast: 'au moins {expectedNumberOfGuests}',
            waitlist: {
                maxNumberOfGuestsError:
                    "Vous pouvez ajouter jusqu'à {maxGuestAmount} membres du groupe familial de votre compte Nintendo lors de votre inscription.",
                noTicketsSelectedError:
                    'Sélectionnez au moins un événement pour pouvoir continuer.',
                claimTempTicketsError: "Une erreur est survenue lors de l'acceptation de l'offre.",
                passWaitlistError: "Une erreur est survenue lors du refus de l'offre.",
            },
        },
        drawing: {
            drawing: 'Tirage',
            firstComeFirstServed: 'Premier arrivé, premier servi',
            enterForAChance: "Inscrivez-vous pour avoir une chance de participer à l'événement",
            youHaveAlreadyEntered: 'Vous vous êtes déjà inscrit(e) au tirage pour cette activité.',
            drawingRules: 'Règlement du tirage',
            ruleX: 'Règlement : {rule}',
            status: {
                pending: 'En attente de tirage ({partySize} {type})',
                notSelected: '',
                notWinner: 'Non sélectionné(e)',
                winner: 'Sélectionné(e)',
            },
        },
        account: {
            accountRequired: 'Compte Nintendo requis',
            pleaseLogin: {
                basic: 'Veuillez {0} à votre compte Nintendo.',
                toView: 'Veuillez {0} à votre compte Nintendo pour consulter vos {1}.',
                toAccept:
                    'Veuillez {0} à votre compte Nintendo pour accepter les conditions générales.',
            },
            requiredToRSVP:
                "Un compte Nintendo est nécessaire pour s'inscrire. Veuillez vous connecter ou créer un compte gratuit pour continuer.",
            rsvpWillBeHeld: '',
            time: '10 minutes',
            checkIn: {
                title: '',
                presentCode: '',
                poorInternetConnectivity:
                    "En cas de mauvaise connexion internet lors de l'événement, nous vous recommandons d'imprimer ce Pass soit à partir de cette page, soit à partir de l'e-mail que nous vous avons envoyé à l'adresse e-mail associée à votre compte Nintendo. (Si vous ne voyez pas cet e-mail, veuillez vérifier votre dossier de courrier indésirable).",
                checkInCode: '',
                appleWalletTM:
                    "Cartes Apple est une marque commerciale d'Apple Inc., déposée aux États-Unis et dans d'autres pays.",
            },
            passes: {
                warpPipePass: 'Warp Pipe Pass',
                warpPipePasses: 'Warp Pipe Pass',
            },
            rsvps: {
                activeRSVPs: '',
                drawingEntries: '',
                rsvps: 'Billets et Warp Pipe Pass',
                viewPass: 'Voir vos Pass',
                reservationUpdated: '« {0} » a été mis à jour.',
                reservationRemoved: '« {0} » a été supprimé.',
                ticketAdded: 'Vous avez bien reçu votre {ticketName}.',
                drawingTicketAdded: 'Vous avez participé avec succès au tirage pour {ticketName}.',
                warpPipePassAdded: 'Vous avez bien reçu votre Pass pour {ticketName}.',
                waitlistAdded: "Vous avez été ajouté(e) à la liste d'attente pour {eventName}.",
                waitlistsUpdated:
                    "Les détails de votre inscription à la liste d'attente pour {eventName} ont été mis à jour.",
                edit: {
                    title: "Changer d'heure",
                },
                cancel: {
                    title: '',
                    entry: 'Annuler la participation au tirage',
                    pass: '',
                    ticket: 'Annuler un billet',
                    areYouSure: '',
                    time: 'dddd D MMM YYYY - HH:mm',
                },
                details: {
                    pass: {
                        ios: require('../images/pass/ios/fr/apple_wallet_badge_fr.svg'),
                    },
                    noneFound: '',
                    requiredForCheckIn: 'Ce {0} est nécessaire pour vous enregistrer.',
                    addToCalendar: 'Ajouter au calendrier',
                },
            },
        },
        noneFound: {
            events: "Aucun événement n'est actuellement ouvert aux inscriptions. Veuillez revenir ultérieurement.",
            activities: "Aucune activité n'est actuellement disponible pour cet événement.",
            timeSlots: "Aucun créneau horaire n'a été trouvé pour cette activité.",
            reservations: '',
            warpPipePasses: 'Vous ne possédez actuellement aucun Warp Pipe Pass actif.',
            tickets: 'Vous ne possédez actuellement aucun billet actif pour un événement.',
        },
        footer: {
            disclaimer:
                "Les Pass et/ou billets sont à usage unique, non transférables et ne peuvent pas être vendus. Les Pass et/ou billets peuvent être échangeables contre un accès prioritaire à la file d'attente d'un jeu ou d'un événement sélectionné à une heure donnée et ne pas être échangeables s'ils sont utilisés à une heure différente. QR Code est une marque déposée de DENSO WAVE INCORPORATED. Les marques de commerce de Nintendo sont la propriété de Nintendo. © Nintendo.",
            privacyPolicy: 'politique de confidentialité',
            privacyPolicyUrl:
                'https://www.nintendo.com/fr-fr/Mentions-legales/Politique-de-confidentialite-du-site-web/Politique-de-confidentialite-du-site-web-637785.html',
            termsOfUse: "Conditions d'utilisation",
            termsOfUseUrl: '',
        },
        dates: {
            day: 'DD',
            dayOfWeekShort: 'ddd',
            dayOfWeek: 'dddd',
            fullDate: 'ddd DD MMMM, YYYY - H:mm',
            dateRangeFirst: 'DD MMM',
            dateRangeSecond: 'DD MMM YYYY',
            hourMinute: 'H:mm',
            hourMinutesTimezone: 'H:mm',
            month: 'MMM',
            monthDateYear: 'DD MMM YYYY',
            monthDateYearLong: 'DD MMMM YYYY',
            range: ' - ',
            reservationDate: 'dddd D MMM YYYY',
            reservationDateShort: 'ddd D MMM YYYY',
            reservationTime: 'H:mm',
            registrationDate: 'ddd DD MMM YYYY HH:mm',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'H:mm',
                timeSlotHourMinute: 'H:mm',
                timeSlotTop: 'ddd',
                timeSlotMiddle: 'DD',
                timeSlotBottom: 'MMM',
            },
            eventTickets: {
                dateRange: 'ddd DD MMM YYYY',
                time: 'H:mm',
                same_day_time: 'HH:mm',
                timeZone: '',
                cancel: {
                    time: 'dddd D MMM YYYY - HH:mm',
                },
                waitlistClaimBy: 'H:mm [le] dddd D MMMM',
            },
            birthday: 'D MMMM YYYY',
            formatting: {
                lowerCaseDayOfWeekMidSentence: true,
                ISO8601ToTimezone: '',
            },
        },
        countries: {
            title: 'Pays : {0}',
            values: {
                ALL: 'Tous',
                US: "États-Unis d'Amérique",
                CA: 'Canada',
                // JP: "Japon",
                AU: 'Australie',
                GB: 'Royaume-Uni & Irlande',
                DE: 'Allemagne',
                FR: 'France',
                ES: 'Espagne',
                IT: 'Italie',
                NL: 'Pays-Bas',
                // PT: 'Portugal',
                // BE: 'Belgique',
                // CH: 'Suisse',
                // AT: 'Autriche',
                MX: 'Mexique',
            },
        },
        locales: {
            en_US: "Anglais (États-Unis d'Amérique)",
            en_GB: 'Anglais (Royaume-Uni)',
            en_AU: 'Anglais (Australie)',
            de_DE: 'Allemand',
            fr_FR: 'Français (France)',
            fr_CA: 'Français (Canada)',
            it_IT: 'Italien',
            es_ES: 'Espagnol (Espagne)',
            es_MX: 'Espagnol (Amérique latine)',
            nl_NL: 'Néerlandais',
            // pt_PT: '',
        },
        files: {
            fileCategory: {
                GENERAL_INFO: 'Informations générales',
                LEGAL: 'Informations légales',
                TOURNAMENT_INFO: 'Informations liées aux tournois',
            },
        },
    },
    en_US,
);

export default fr_FR;
