// Mexican Spanish (Language-Country Code: es-MX) Locale File.

import defaultsDeep from 'lodash.defaultsdeep';

import { PublicLocalizationObject } from './en_US';
import es_ES from './es_ES';

const es_MX: PublicLocalizationObject = defaultsDeep(
    {
        general: {
            soldOut: 'YA NO HAY LUGARES DISPONIBLES',
            currentLanguage: 'Español (México)',
            areYouSure: '¿Seguro que quieres quitarte de la lista?',
        },
        tabs: {
            myTicketsAndPasses: 'Mis boletos / Pases',
        },
        events: {
            details: {
                tabs: {
                    tickets: 'Boletos',
                },
                accessCode: {
                    error: {
                        registrationClosed: 'El registro para este boleto ha concluido.',
                    },
                },
                waitlist: {
                    available: 'Lista de espera disponible:',
                    drawingOver:
                        'La rifa ha concluido, pero ¡quizá todavía tengas una oportunidad de asistir al evento! Apúntate en la lista de espera y te informaremos si se queda algún espacio disponible. Ten en cuenta que apuntarte en la lista de espera no garantiza que consigas boletos.',
                    drawingLost:
                        'Tu participación para conseguir boletos no ha resultado seleccionada. Si quieres, puedes apuntarte en la lista de espera y te informaremos si se queda algún espacio disponible. Ten en cuenta que apuntarte en la lista de espera no garantiza que consigas boletos.',
                    waitlistJoined:
                        'Te has apuntado en la lista de espera de este evento: {eventName}',
                    waitlistLink: 'Ver tu lista de espera >',
                    waitlistOffer:
                        '¡Genial! Un lugar/varios lugares han quedado libres en una de las listas de espera en las que te has apuntado. Tienes hasta el {monthDay} a las {time} para solicitarlos; si no lo haces, se los ofreceremos a la siguiente persona en la lista de espera.',
                    offerPassed:
                        'Has declinado recibir {ticketName}. Si quieres, puedes apuntarte de nuevo en la lista de espera,',
                    offerExpired:
                        'Ha caducado la posibilidad de recibir {ticketName}. Si quieres, puedes apuntarte de nuevo en la lista de espera,',
                    waitlistClosed: 'La lista de espera se ha cerrado.',
                    button: {
                        join: 'Apuntarse en la lista de espera',
                        edit: 'Editar tu lista de espera',
                    },
                },
            },
            dataAttribute: {
                FULL_NAME: 'Nombre completo',
                FIRST_NAME: 'Nombre (s)',
                LAST_NAME: 'Apellidos',
                MAILING_ADDRESS: {
                    aptOrSuite: 'Departamento',
                    state: 'Colonia',
                },
            },
            ageRatingUrl:
                'https://www.dof.gob.mx/nota_detalle.php?codigo=5606047&fecha=27/11/2020#gsc.tab=0',
        },
        activities: {
            details: {
                title: 'Detalles de la actividad',
                ticketIsRequired:
                    'Se requiere tener un boleto para este evento antes de registrarse para esta actividad. {link}.',
                eventTicketRequired: 'Se requiere un boleto para entrar al evento.',
                ticketIsPendingDrawing:
                    'Tu boleto para el evento todavía depende de una rifa. Regresa cuando se haya realizado la rifa.',
                checkForTicketAvailability: 'Comprueba aquí la disponibilidad de boletos',
                label: {
                    startDate: 'La fecha de inicio es el {startDate}',
                },
            },
            types: {
                GAME_DEMO: 'Demo de un juego',
                MEET_GREET: 'Meet & Greet',
                PRODUCT_LAUNCH: 'Lanzamiento de producto',
            },
        },
        reservations: {
            timeRemainingForWaitlist:
                '¡Genial! Han quedado disponibles {numTickets} {ticketName} en la lista de espera en la que te has apuntado. Si quieres asistir al evento, acepta los boletos antes de que se agote el tiempo: {timeRemaining}',
            waitlistThingsToKeepInMind: 'Algunos detalles para tener en cuenta:',
            waitlistThingsToKeepInMindItem1:
                'Si aceptas estos boletos, se te quitará de la lista de espera para cualquier otro boleto del siguiente evento: {eventName}.',
            waitlistThingsToKeepInMindItem2:
                'Si abandonas esta página, no perderás la oportunidad de aceptar lo(s) boletos(s). Podrás aceptarlo(s) hasta la fecha designada para ello.',
            waitlistThingsToKeepInMindItem3:
                'Si no deseas asistir al evento, puedes declinar los boletos. Así quedarán disponibles para la siguiente persona en la lista de espera.',
            waitlistThingsToKeepInMindItem4:
                'Declinar los boletos no afectará a ninguna otra lista de espera en la que te hayas apuntado para el siguiente evento: {eventName}. También podrás volverte a apuntar en la lista de espera más adelante.',
            nickname: 'Usuario de la cuenta Nintendo',
            adultAcknowledgement:
                'Al seleccionar esta casilla, certifico que yo y el/los invitados mayores de 18 años que forman parte de mi grupo familiar hemos leído las políticas de privacidad y hemos aceptado los términos y condiciones que se enlistan más abajo.',
            childAcknowledgement:
                'Al seleccionar esta casilla, certifico que soy el padre/madre/tutor del o de los invitados menores de 18 años que forman parte de mi grupo familiar.Asimismo, acepto en su nombre las políticas de privacidad y los términos y condiciones que se enlistan más arriba.',
            adultGuestsAcknowledgement:
                'Al seleccionar esta casilla, confirmo que tengo el permiso de mis invitados adultos para añadirlos como invitados para este evento y para que reciban una invitación por correo electrónico. Cada invitado adulto debe aceptar los términos y condiciones incluidos en el correo electrónico de invitación antes de asistir al evento.',
        },
        tickets: {
            ticket: 'boleto',
            tickets: 'boletos',
            ticketRequiredForCheckIn: 'Se require este {0} para asistir al evento',
            ticketsRequiredForCheckIn: 'Se requieren estos {0} para asistir al evento',
            getTickets: 'Obtener boletos',
            eventTicket: 'Boleto del evento',
            eventTickets: 'Boletos del evento',
            viewTickets: 'Ver boletos',
            cancelOtherTicket: 'Cancela tu otro boleto antes de registrarte para este',
            soldOut: 'No quedan lugares',
            adultRequired: 'Se requiere la compañía de un adulto',
            backToMyTicketsPasses: 'Volver a mis boletos/pases',
            notFound: 'No se han encontrado boletos',
            unknownTicketType: 'Tipo de boleto desconocido',
            receivedTickets: 'Has recibido tus boletos para {resourceName} {resourceDates}.',
            drawings: {
                wonDrawing: 'Has ganado la rifa de boletos de {resourceName} {resourceDates}.',
                lostDrawing: 'Lo sentimos, pero no has ganado la rifa para este boleto.',
                pendingDrawing:
                    'Te has registrado para participar en la rifa de boletos de {resourceName} {resourceDates}.',
            },
            registration: {
                ticketDetails: 'Información del boleto',
                registrant: {
                    requiredFields: 'Los espacios marcados con * son obligatorios.',
                },
                guest: {
                    guestsRemovedTicket:
                        'Se ha eliminado a {guests} como invitado/a porque ya se había registrado para un boleto de este evento.',
                    guestsRemovedDrawing:
                        'Se ha eliminado a {guests} como invitado/a porque ya se había registrado para participar en la rifa de boletos de este evento.',
                    guestFamilyHelpUrl:
                        'https://es-americas-support.nintendo.com/app/answers/detail/p/992/c/854/a_id/23078',
                    guestFamilyUrl:
                        'https://es-americas-support.nintendo.com/app/answers/detail/p/992/c/854/a_id/23078',
                },
            },
            cancelTicket: {
                cancelTicketModalTitle: 'Cancelar boleto',
                cancelLink: 'Cancelar este boleto',
                cancelTicketModalWarning: {
                    line1: 'No podrás asistir a este evento sin boleto',
                    line2: 'Se cancelarán tu boleto y tu Warp Pipe Pass(es)',
                    line3: 'Al cancelar tu boleto, se cancelarán automáticamente el boleto o boletos y el o los Warp Pipe Pass(es) de todos los miembros de tu grupo(si corresponde). Todos los miembros recibirán un correo electrónico en el que se les notificará sobre la cancelación.',
                    line4: 'Las cancelaciones son definitivas. Los boletos y los Warp Pass Passes no se considerarán válidos si se cancelan.',
                },
                areYouSure: '¿Seguro que quieres cancelar este boleto?',
                cancelButton: 'Cancelar boleto',
                cancelYourTicket: '¿No puedes asistir? Cancela tu boleto o boletos',
            },

            cancelDrawingEntry: {
                cancelTicketModalWarning: {
                    line1: 'Ya no participarás en la rifa de boletos.',
                },
            },
            cancelPassDrawingEntry: {
                cancelTicketModalWarning: {
                    line1: 'Ya no participarás en la rifa de boletos.',
                },
            },
            waitlist: {
                waitlist: 'Lista de espera',
                title: 'Apuntarse en la lista de espera de: {eventName}',
                editTitle: 'Editar la lista de espera de: {eventName}',
                subtitle: '¡Te avisaremos en cuanto quede algún espacio disponible!',
                notice: {
                    line1: '{notice}: Los boletos se ofrecerán por orden de llegada a las personas que los soliciten, según el momento en el que se apuntaron en la lista de espera, y según también el número de boletos solicitado y los boletos que se encuentren disponibles.',
                    line2: 'Solo podrás recibir un ofrecimiento de boletos por turno.',
                    line3: 'Tendrás {timeFrame} para registrarte para el evento antes de que caduque el ofrecimiento.',
                    line4: 'Si aceptas un ofrecimiento, se te quitará de la lista de espera para cualquier boleto restante.',
                    notice: 'Atención:',
                },
                individualTicketsHeader: 'Boletos individuales',
                familyTicketsHeader: 'Boletos familiares',
                selectAll: 'Selecciona todos los boletos de {waitlistType}',
                summaryCheckbox:
                    'Al seleccionar esta casilla, entiendo que apuntarme en la lista de espera no garantiza que reciba boletos. Si se presenta una oportunidad, tendré 36 horas para aceptarlos.',
                summaryFooter:
                    'Estás a punto de enviar una solicitud para apuntarte en la lista de espera del siguiente evento: {eventName}. Recibirás un aviso por correo electrónico en el caso de que podamos ofrecerte boletos. Para asegurarte de que sigues recibiendo nuestros correos electrónicos, añade nuestra dirección de correo electrónico ({emailAddress}) en tu libreta de direcciones. También revisa de vez en cuando la carpeta de spam. Tendrás 36 horas para aceptar los boletos ofrecidos.',
                onWaitlistRequestingTickets:
                    'En la lista de espera (para {count} boleto(s) de evento)',
                confirmRemoveSelfFromWaitlistsForEvent:
                    'Estás a punto de quitarte de las listas de espera para el siguiente evento: {eventName}. Si lo haces, no recibirás ninguna notificación en el caso de que se queden más boletos disponibles.',
                confirmRemoveSelfFromWaitlist:
                    'Al cancelar tu solicitud, te quitarás de la lista de espera para siguiente evento: {ticketName}. Si lo haces, no recibirás ninguna notificación en el caso de que se queden más boletos disponibles.',
                congratulationsTicketWaitlistOffered:
                    '¡Enhorabuena, podemos ofrecerte boletos para el siguiente evento: {eventName}! Acéptalas antes del {claimByDate} para asegurarte tu lugar.',
                congratulationsEventTicketWaitlistOffered:
                    '¡Enhorabuena, podemos ofrecerte boletos! Amplía para ver más detalles.',
                claimTickets: 'Aceptar boletos',
                passOnTickets: 'Declinar boletos',
                passOnTicketsNotice:
                    'Al declinar este ofrecimiento, no podrás aceptar los boletos que te ofrecemos y se te quitará de la lista de espera.',
                removeWaitlistTicketsWarning:
                    'Al cancelar tu solicitud, te quitarás de la lista de espera para los boletos y no se te notificará en el caso de que otros boletos se queden disponibles.',
            },
            filter: {
                allDays: 'Todos los días',
                filterTicketsLabel: 'Filtrar boletos:',
                ticketTypes: {
                    ALL: 'Todas los boletos',
                    INDIVIDUAL: 'Boletos individuales',
                    FAMILY: 'Boletos familiares',
                },
            },
        },
        terms: {
            addedToDrawing:
                '{invitor} te ha añadido para participar en la rifa de boletos del siguiente evento de Nintendo.Para poder participar, debes aceptar los términos del evento.',
            addedToEvent:
                '{invitor} te ha enviado un boleto para el siguiente evento de Nintendo.Para poder asistir a este evento, debes aceptar primero los términos del evento.',
            completeInformation: 'Llena la siguiente información:',
        },
        error: {
            eventsError:
                'Se produjo un error al intentar mostrar los eventos. Inténtalo de nuevo más tarde.',
            eventDetailsError:
                'Se produjo un error al intentar mostrar la información del evento. Inténtalo de nuevo más tarde.',
            activityDetailsError:
                'Se produjo un error al intentar mostrar la información de la actividad. Inténtalo de nuevo más tarde.',
            timeSlotError:
                'Se produjo un error al intentar mostrar las horas disponibles. Inténtalo de nuevo más tarde.',
            tempReservationError:
                'Se produjo un error al intentar mantener esta reservación. Inténtalo de nuevo más tarde.',
            myRSVPsError:
                'Se produjo un error al intentar acceder a la confirmación de tu participación. Inténtalo de nuevo más tarde.',
            dataCollectionError:
                'Se produjo un error al intentar acceder a los datos. Inténtalo de nuevo más tarde.',
            reservationCouldNotBeConfirmed:
                'No se ha podido confirmar la reservación. Inténtalo de nuevo más tarde.',
            reservationCancelFailure:
                'Se produjo un error durante la cancelación. Inténtalo de nuevo más tarde.',
            reservationFormError:
                'Debes aceptar los términos para apuntarte en la lista de espera.',
            waitlistRsvpTimeOut: 'Lo sentimos, pero tu oportunidad de aceptar boletos ha caducado.',
            ticketConfirmationFailed:
                'No se ha podido confirmar el boleto del evento. Revisa la información proporcionada e inténtalo de nuevo.',
            serviceError: {
                GP0009: 'No hay espacios disponibles en la hora solicitada.',
                GP0015: 'No se encontró la reservación que se quiere cancelar.',
                GP0243: 'Ya se te ha añadido a un boleto para este evento.',
                unknown: 'Se produjo un error.',
            },
            serviceErrorModal: {
                GP212: {
                    body: 'El registro para este boleto no está disponible todavía.',
                },
                GP213: {
                    body: 'El registro para este boleto ha concluido.',
                },
                GP0214: {
                    title: 'Boleto del evento no disponible',
                    body: 'Lo sentimos, pero ya no quedan lugares para el boleto del evento que has seleccionado. Consulta la disponibilidad de otras boletos.',
                },
                GP0218: {
                    body: 'Ya participas en la rifa de este boleto.',
                },
                GP220: {
                    body: 'El registro para este boleto no está disponible todavía.',
                },
                GP221: {
                    body: 'El registro para esta boleto ha concluido.',
                },
                unknown: {
                    body: 'Lo sentimos, pero se produjo un error desconocido.Inténtalo de nuevo.',
                },
            },
            formError: {
                required: 'Espacio obligatorio',
                default: 'Espacio no válido',
            },
            ticketCancelingError: 'Error al cancelar un boleto',
            minNumberOfGuestsError:
                'Añade {atLeastNum} miembro/s del grupo familiar de la cuenta Nintendo para proceder al registro.',
            waitlist: {
                noTicketsSelectedError: 'Selecciona al menos un boleto de evento para continuar',
                claimTempTicketsError:
                    'Se ha producido un error al intentar aceptar el o los boletos de la lista de espera',
                passWaitlistError:
                    'Se ha producido un error al intentar declinar el o los boletos de la lista de espera',
            },
        },
        account: {
            pleaseLogin: {
                toView: 'Debes {0} en tu cuenta Nintendo para ver tus {1}.',
                toAccept: 'Debes {0} en tu cuenta Nintendo para aceptar los términos.',
            },
            checkIn: {
                poorInternetConnectivity:
                    'En caso de que la conexión a internet no sea estable en el evento, te recomendamos que imprimas el Warp Pipe Pass desde esta página o desde el correo electrónico que hemos enviado a la dirección de correo electrónico vinculada a tu cuenta Nintendo. (Si no puedes ver dicho correo electrónico, revisa tu carpeta de spam.)',
            },
            rsvps: {
                rsvps: 'Boletos y Warp Pipe Passes',

                cancel: {
                    ticket: 'Cancelar boleto',
                },
                details: {
                    pass: {
                        ios: require('../images/pass/ios/es/apple_wallet_badge_mx.svg'),
                    },
                },
            },
        },
        noneFound: {
            events: 'No hay eventos para los cuáles registrarse actualmente. Vuelve por aquí más tarde.',
            tickets: 'Por el momento no tienes ningún boleto activo para este evento. ',
        },
        footer: {
            disclaimer:
                'El Warp Pipe Pass y/o boleto solo puede usarse una vez, no es transferible y no está permitida su venta. El Warp Pipe Pass y/o boleto puede canjearse para recibir acceso prioritario en la fila para acceder a un juego o evento determinados a una hora designada; por el contrario, no se podrá canjear si se utiliza a una hora que no sea la designada. QR Code (código QR) es una marca registrada de DENSO WAVE INCORPORATED. Las marcas de Nintendo son propiedad de Nintendo. © Nintendo.',
            privacyPolicyUrl: 'https://www.nintendo.com/es-mx/privacy-policy/',
            termsOfUse: 'Términos de uso',
            termsOfUseUrl: 'https://www.nintendo.com/es-mx/terms-of-use/',
        },
        dates: {
            fullDate: 'ddd, DD [de] MMMM, YYYY [a las] H:mm [hrs.]',
            dateRangeFirst: 'DD [de] MMM',
            dateRangeSecond: 'DD [de] MMM, YYYY',
            hourMinute: 'H:mm [hrs.]',
            hourMinutesTimezone: 'H:mm [hrs.] z',
            monthDateYear: 'DD [de] MMM, YYYY',
            monthDateYearLong: 'DD [de] MMMM, YYYY',
            reservationDate: 'dddd, D [de] MMM, YYYY',
            reservationDateShort: 'ddd, D [de] MMM, YYYY',
            reservationTime: 'H:mm [hrs.]',
            registrationDate: 'ddd, DD [de] MMM, YYYY [a las] HH:mm [hrs.]',
            timeSlot: {
                timeSlotHourMinuteTimezone: 'H:mm [hrs.] z',
                timeSlotHourMinute: 'H:mm [hrs.]',
            },
            eventTickets: {
                dateRange: 'ddd, DD [de] MMMM, YYYY',
                time: 'H:mm [hrs.]',
                same_day_time: 'HH:mm [hrs.]',
                cancel: {
                    time: 'dddd, D [de] MMM, YYYY [a las] HH:mm [hrs.]',
                },
                waitlistClaimBy: 'H:mm [hrs.] [del] dddd, D [de] MMMM',
            },
            birthday: 'D [de] MMMM, YYYY',
            formatting: {
                lowerCaseDayOfWeekMidSentence: true,
                ISO8601ToTimezone: 'z',
                eventTickets: {
                    waitlistClaimBy: 'h:mm A [on] dddd, MMMM Do',
                },
            },
            locales: {
                // To keep order the same, we need to explicitly call ...es_ES.locales
                ...es_ES.locales,
                nl_NL: 'Holandés',
            },
        },
    },
    es_ES,
);

export default es_MX;
